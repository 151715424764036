import React from 'react'
import {ParagraphText, Icon} from '@thryvlabs/maverick'
import {PaymentMethodContainer} from '../ui/payment-information'
import {plaidIcon} from '../../assets'
import {AutopayTerms} from './autopay-terms'
import {useFlags} from 'launchdarkly-react-client-sdk'

const PaymentMethod = ({
  paymentMethod,
  handleChangePaymentMethod,
  paymentMethods,
  is5Order,
  isDesktop,
}) => {
  const {frontendUpdatedTerms} = useFlags()

  return (
    <PaymentMethodContainer
      is5Order={is5Order}
      isDesktop={isDesktop}
      className={`d-flex ${!frontendUpdatedTerms && 'mb-4'} ${
        is5Order && isDesktop
          ? 'justify-content-start'
          : 'justify-content-center'
      }`}
    >
      {paymentMethods.map((method, index) => {
        const isPlaid = method.name === 'Bank Transfer with Plaid'

        return (
          <div
            key={index}
            className={`payment-method p-2 ${
              paymentMethod.value === method.value ? 'active' : ''
            }`}
            onClick={() => handleChangePaymentMethod(method)}
          >
            {isPlaid && is5Order ? (
              <img
                src={plaidIcon}
                alt="plaid icon"
                height="16px"
                width="16px"
                className="mb-1"
              />
            ) : (
              <Icon
                type="solid"
                variant={method.icon}
                className={`${is5Order && 'mb-1'}`}
                style={{
                  height: is5Order ? '11px' : 'auto',
                  width: is5Order ? '15px' : 'auto',
                }}
              />
            )}
            <ParagraphText variant="sm" className="font-weight-bold m-0">
              {isPlaid && is5Order ? 'Transfer with Plaid' : method.name}
            </ParagraphText>
          </div>
        )
      })}
      {!isDesktop && frontendUpdatedTerms && <AutopayTerms />}
      {paymentMethod.value === 'ach' && (
        <div className="achRoundedRectanlge">
          Note: A monthly discount will be applied. Check your invoice for
          details.
        </div>
      )}
    </PaymentMethodContainer>
  )
}

export {PaymentMethod}
