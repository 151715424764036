import React from 'react'

import {
  keapDesktopProjectionIcon,
  keapGetMoreReviewsIcon,
  keapHeatMapIcon,
  keapPathsIcon,
  keapSocialMediaIcon,
  keapStatusFiltersIcon,
  keapTVIcon,
  keapWebsiteIcon,
} from '../../../assets'
import {
  KeapMcIconImage,
  KeapMCIconItem,
  KeapMCIconList,
  KeapMCIconText,
} from './styled.components'

const items = [
  {
    text: 'Social Media Management',
    image: keapSocialMediaIcon,
  },
  {
    text: 'AI Review Response',
    image: keapStatusFiltersIcon,
  },
  {
    text: 'Online Listings & Reviews',
    image: keapGetMoreReviewsIcon,
  },
  {
    text: 'Real Time Analytics',
    image: keapDesktopProjectionIcon,
  },
  {
    text: 'Heatmap',
    image: keapHeatMapIcon,
  },
  {
    text: 'Professionally Designed Website',
    image: keapWebsiteIcon,
  },
  {
    text: 'Reporting Center',
    image: keapPathsIcon,
  },
  {
    text: 'Track Offline Marketing',
    image: keapTVIcon,
  },
]

export function KeapMCIcons() {
  return (
    <KeapMCIconList>
      {items.map(({text, image}) => (
        <KeapMCIconItem key={text}>
          <KeapMcIconImage src={image} alt={text} />{' '}
          <KeapMCIconText>{text}</KeapMCIconText>
        </KeapMCIconItem>
      ))}
    </KeapMCIconList>
  )
}
