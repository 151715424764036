import styled from '@emotion/styled'

export const KeapPartnerPrimaryButton = styled.button`
  cursor: pointer;
  color: #fff;
  background-color: rgba(255, 80, 0);
  padding-inline: 1.5rem;
  padding-block: 0.5rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  box-sizing: border-box;
`

export const KeapPartnerOutlinedButton = styled(KeapPartnerPrimaryButton)`
  cursor: pointer;
  color: rgb(255, 80, 0);
  background-color: #fff;
  padding-inline: 1.5rem;
  padding-block: 0.5rem;
  border-radius: 0.25rem;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
  font-family: var(--bs-body-font-family);
  box-sizing: border-box;
  border: 1px solid rgb(255, 80, 0);
`

export const DeletableCouponChip = styled.span`
  display: flex;
  padding-block: 0.125rem;
  padding-inline-start: 0.25rem;
  padding-inline-end: 0.25rem;
  column-gap: 0.5rem;
  align-items: center;
  background-color: rgba(5, 120, 254, 0.08);
  font-size: 0.75rem;
  margin: 0px 10px 5px 0px;
  color: rgb(4, 84, 176);
  box-sizing: border-box;
`

export const XButton = styled.button`
  background-color: #00000015;
  color: inherit;
  border: none;
  width: 1rem;
  height: 1rem;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  line-height: 0px;
  font-weight: bold;
  box-sizing: border-box;
  font-family: var(--bs-body-font-family);
`

export const GenerateLinkModalFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-start: 1.5rem;
`
