import React from 'react'
import SkeletonLoader from '../skeleton-loader'
import {ParagraphText, Button, Icon, Checkbox} from '@thryvlabs/maverick'

import {thryvSalesLogo} from '../../assets'
import {MainMobileContainer} from '../ui/payment-information'
import {CartDetails} from './cart-details'
import {PaymentMethod} from './payment-method'
import {PaymentForm} from './recurly-payment-form'
import {AchPaymentForm} from './ach-payment-form'
import {InvoicePaymentForm} from './invoice-payment-form'
import {BillingNoticeModal} from '../../components/billing-notice-modal'
const TOTAL_STEPS = 5

const StepContainer = ({
  title,
  children,
  nextStep,
  prevStep,
  continueButtonText,
  showContinueButton = true,
  disableContinueButton = false,
}) => {
  return (
    <div className="step__container m-2 mt-4">
      {title && (
        <div className="d-flex align-items-center">
          {typeof prevStep === 'function' && (
            <Icon
              type="solid"
              variant="chevronLeft"
              className="mr-4"
              onClick={prevStep}
            />
          )}
          <ParagraphText variant="lg" className="font-weight-bold mb-0">
            {title}
          </ParagraphText>
        </div>
      )}
      <div className="d-flex justify-content-center align-items-center">
        {children}
      </div>
      {showContinueButton && (
        <div className="d-flex justify-content-center align-items-center">
          <Button
            variant="primary"
            type="button"
            className="step__container__button"
            onClick={nextStep}
            disabled={disableContinueButton}
          >
            {continueButtonText || 'Continue'}
          </Button>
        </div>
      )}
    </div>
  )
}

const MobileLayout = ({
  paymentState,
  isLoading,
  calculateTaxes,
  calculatingTaxes,
  setCalculatingTaxes,
  handleChangePaymentMethod,
  paymentMethods,
  checkBoxesValues,
  handleOnCheckBoxChange,
  savePaymentContinue,
  uuid,
  paymentRequestState,
  is5Order,
  isAddressUpdated,
  setIsAddressUpdated,
  updateParentAddress,
}) => {
  const [currentStep, setCurrentStep] = React.useState(1)
  const [name, setName] = React.useState('')

  const [addressInformation, setAddressInformation] = React.useState({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    postalCode: '',
    state: '',
  })
  // const cardDetails = useRef({})
  const [isBillingNoticeDisplayed, setIsBillingNoticeDisplayed] =
    React.useState(false)
  console.log(
    'isBillingNoticeDisplayed from old-flow-desktop:: ',
    isBillingNoticeDisplayed,
  )
  const multiplePaymentOptions =
    paymentState.paymentInfo?.country === 'US' &&
    !paymentState.paymentInfo?.isInvoiceBilled

  const paymentPreview = paymentState.paymentInfo?.paymentPreview
  const isSEOPreviewAvailable = paymentPreview?.apps?.find(({planCode}) =>
    ['seo-5-thryv-m2m', 'seombg-5-thryv-m2m'].includes(planCode),
  )
  console.log(isSEOPreviewAvailable)

  const nextStep = () => {
    let _currentStep = currentStep
    if (currentStep >= TOTAL_STEPS - 1) {
      _currentStep = TOTAL_STEPS
    } else {
      _currentStep = currentStep + 1
    }
    setCurrentStep(_currentStep)
  }

  const prevStep = () => {
    let _currentStep = currentStep
    if (currentStep <= 1) {
      _currentStep = 1
    } else {
      _currentStep = currentStep - 1
    }
    setCurrentStep(_currentStep)
  }

  const handleSubmitOrder = () => {
    savePaymentContinue()
    setIsAddressUpdated(true)
    if (paymentState.paymentInfo.isInvoiceBilled) {
      setCurrentStep(5)
    } else {
      nextStep()
    }
  }

  React.useEffect(() => {
    if (paymentRequestState.requested && !paymentRequestState.hasErrors) {
      setCurrentStep(TOTAL_STEPS - 1)
    }
  }, [paymentRequestState])

  return (
    <MainMobileContainer>
      {isLoading ? (
        <SkeletonLoader width="100%" height="95vh" />
      ) : (
        <>
          {paymentState?.paymentInfo?.flow === 'upgrade' && (
            <div>
              <BillingNoticeModal
                setIsBillingNoticeDisplayed={setIsBillingNoticeDisplayed}
                calledFromPaymentLink={true}
              />
            </div>
          )}
          <div className="d-flex justify-content-start mb-4">
            <img src={thryvSalesLogo} alt="Thryv Sales Logo" />
          </div>
          {currentStep !== 5 && (
            <>
              <ParagraphText
                variant="lg"
                className="font-weight-bold mt-2 screen__title"
              >
                Payment details
              </ParagraphText>
              <CartDetails
                paymentState={paymentState}
                is5Order={is5Order}
                currentStep={currentStep}
              />
            </>
          )}
          <div>
            {paymentState.paymentInfo.country === 'AU' && currentStep !== 5 && (
              <ParagraphText
                variant="sm"
                className="font-weight-bold mt-4
            "
              >
                *All plans include GST
              </ParagraphText>
            )}
          </div>
          {currentStep === 1 && (
            <StepContainer
              title={multiplePaymentOptions ? 'Select payment method' : ''}
              nextStep={nextStep}
            >
              {multiplePaymentOptions && (
                <PaymentMethod
                  paymentMethod={paymentState.paymentMethod}
                  handleChangePaymentMethod={handleChangePaymentMethod}
                  paymentMethods={
                    paymentState.paymentInfo.country === 'US'
                      ? paymentMethods
                      : [paymentMethods[0]]
                  }
                />
              )}
            </StepContainer>
          )}
          {paymentState?.paymentMethod?.value === paymentMethods[0].value &&
            !paymentState?.paymentInfo?.isInvoiceBilled &&
            (currentStep === 2 || currentStep === 3) && (
              <StepContainer
                title={
                  currentStep === 2 ? 'Credit / Debit Card' : 'Billing Address'
                }
                nextStep={nextStep}
                prevStep={prevStep}
                showContinueButton={false}
              >
                <PaymentForm
                  calculateTaxes={calculateTaxes}
                  calculatingTaxes={calculatingTaxes}
                  setCalculatingTaxes={setCalculatingTaxes}
                  isMobile={true}
                  showFirstSection={currentStep === 2}
                  showSecondSection={currentStep === 3}
                  nextStep={nextStep}
                  checkBoxesValues={checkBoxesValues}
                  handleOnCheckBoxChange={handleOnCheckBoxChange}
                  businessAddress={paymentState?.paymentInfo?.businessAddress}
                  setName={setName}
                  country={paymentState.paymentInfo.country}
                  bizphone={paymentState.paymentInfo?.businessAddress?.phone}
                />
              </StepContainer>
            )}
          {paymentState?.paymentMethod?.value === paymentMethods[0].value &&
            !!paymentState?.paymentInfo?.isInvoiceBilled &&
            currentStep === 2 && (
              <StepContainer
                title={''}
                nextStep={nextStep}
                prevStep={prevStep}
                showContinueButton={false}
              >
                <InvoicePaymentForm
                  calculateTaxes={calculateTaxes}
                  calculatingTaxes={calculatingTaxes}
                  setCalculatingTaxes={setCalculatingTaxes}
                  checkBoxesValues={checkBoxesValues}
                  handleOnCheckBoxChange={handleOnCheckBoxChange}
                  handleCompleteOrder={handleSubmitOrder}
                  updateParentAddress={updateParentAddress}
                  businessAddress={paymentState.paymentInfo?.businessAddress}
                  country={paymentState?.paymentInfo?.country}
                  setName={setName}
                  setIsAddressUpdated={setIsAddressUpdated}
                  bizphone={paymentState.paymentInfo?.businessAddress?.phone}
                  is5Order={is5Order}
                  isAddressUpdated={isAddressUpdated}
                  uuid={uuid}
                  isMobile={true}
                  setAddressInformation={setAddressInformation}
                  addressInformation={addressInformation}
                />
              </StepContainer>
            )}
          {paymentState?.paymentMethod?.value === paymentMethods[1]?.value &&
            (currentStep === 2 || currentStep === 3) && (
              <StepContainer
                title={'ACH'}
                prevStep={prevStep}
                showContinueButton={false}
              >
                <AchPaymentForm
                  uuid={uuid}
                  setCurrentStep={setCurrentStep}
                  calculateTaxes={calculateTaxes}
                  setCalculatingTaxes={setCalculatingTaxes}
                  useBusinessAddressInfo={
                    checkBoxesValues.useBusinessAddressInfo
                  }
                  setName={setName}
                  checkBoxesValues={checkBoxesValues}
                  handleOnCheckBoxChange={handleOnCheckBoxChange}
                  businessAddress={paymentState?.paymentInfo?.businessAddress}
                  isMobile={true}
                />
              </StepContainer>
            )}

          {currentStep === 4 && (
            <StepContainer
              showContinueButton={true}
              continueButtonText="Complete Purchase"
              nextStep={handleSubmitOrder}
              disableContinueButton={!checkBoxesValues.agreedTerms}
            >
              <div>
                <ParagraphText variant="sm" style={{fontSize: '14px'}}>
                  *This initial Subscription Period is 6 months
                </ParagraphText>
                <Checkbox
                  hasLabel
                  name="agreedTerms"
                  value={checkBoxesValues.agreedTerms}
                  defaultChecked={checkBoxesValues.agreedTerms}
                  onChange={handleOnCheckBoxChange}
                  label={
                    <label style={{marginTop: '5px'}}>
                      By checking this box, you agree to our{' '}
                      <a
                        target="_blank"
                        href="https://corporate.thryv.com/terms/"
                        rel="noreferrer"
                      >
                        Terms & Conditions
                      </a>
                    </label>
                  }
                />
                <ParagraphText variant="sm">
                  By processing your payment, you agree that your personal
                  information will be shared with Thryv’s payment
                  processor—Adyen, N.V. and will be subject to Adyen’s{' '}
                  <a
                    target="_blank"
                    href="https://www.adyen.com/policies-and-disclaimer/privacy-policy"
                    rel="noreferrer"
                  >
                    privacy statement
                  </a>
                  .
                </ParagraphText>
              </div>
            </StepContainer>
          )}
          {currentStep === 5 && (
            <div className="d-flex flex-column align-items-center">
              <Icon
                style={{marginBottom: '25px'}}
                type="solid"
                variant="circleCheck"
                color={is5Order ? '#16A085' : '#5cb95c'}
                width="48px"
                height="48px"
              />
              <ParagraphText
                variant="lg"
                style={{
                  color: is5Order ? '#16A085' : '',
                  textAlign: is5Order ? 'center' : 'left',
                }}
              >
                <strong>
                  Thank you {name}, your payment information is successfully
                  updated!
                </strong>
              </ParagraphText>
              <ParagraphText variant="lg">
                We will use this information to bill your Thryv subscriptions
                each month.
              </ParagraphText>
              <ParagraphText variant="lg">
                You can update your billing information through the My Account
                page.
              </ParagraphText>
              <ParagraphText variant="lg">
                <strong>
                  You are now ready to complete your Thryv Purchase!
                </strong>
              </ParagraphText>
            </div>
          )}
        </>
      )}
    </MainMobileContainer>
  )
}

export default MobileLayout
