import React from 'react'
import SkeletonLoader from '../skeleton-loader'
import {Button, ParagraphText, Checkbox, Icon} from '@thryvlabs/maverick'

import {MainContainer} from '../ui/payment-information'
import {thryvSalesLogo} from '../../assets'
import {PaymentForm} from './recurly-payment-form'
import {PaymentMethod} from './payment-method'
import {AchPaymentForm} from './ach-payment-form'
import {InvoicePaymentForm} from './invoice-payment-form'
import {formatPrice} from '../../utils/currency-format'
import {BillingNoticeModal} from '../../components/billing-notice-modal'

const DropdownProducts = ({paymentState, title}) => {
  const [toggleDropdown, setToggleDropdown] = React.useState(false)
  const {monthlyAddons, semiAnnuallyAddons, oneTimeAddons, paymentInfo} =
    paymentState

  const handleToggleDropdown = () => {
    setToggleDropdown(!toggleDropdown)
  }

  const getAddonsList = () => [
    {
      name: paymentInfo.plan.planName,
      price: formatPrice(paymentInfo.plan.currency.amount),
    },
    ...[...oneTimeAddons, ...monthlyAddons, ...semiAnnuallyAddons]
      .filter(({addonPlanCode, isDomainValid}) => {
        if (addonPlanCode === 'ent-4-website-free' && isDomainValid) {
          return false
        }
        return addonPlanCode !== 'onboarding-fee'
      })
      .map(({name, currency, quantity}) => ({
        name: name,
        price: formatPrice(currency.unitAmount, quantity),
      })),
    ...paymentInfo.coupons
      .map(coupon => {
        let couponDiscountAmount = 0

        const selectedPlanCodes = [
          ...monthlyAddons,
          ...oneTimeAddons,
          {
            code: paymentInfo.plan.planCode,
            name: paymentInfo.plan.name,
            currency: {unitAmount: paymentInfo.plan.currency.amount},
            quantity: 1,
          },
        ]

        if (coupon.discountAmount) {
          couponDiscountAmount = coupon.discountAmount
        } else if (coupon.isPercentDiscount && coupon.appliesToAllPlans) {
          selectedPlanCodes.forEach(addon => {
            if (!addon.code.includes('Setup Fee')) {
              couponDiscountAmount +=
                addon.currency.unitAmount *
                addon.quantity *
                (coupon.discountPercent / 100)
            }
          })
        } else {
          selectedPlanCodes.forEach(addon => {
            if (
              coupon?.appliesToPlans?.includes(addon.code) &&
              !addon.name.includes('Setup Fee')
            ) {
              couponDiscountAmount +=
                addon.currency.unitAmount *
                addon.quantity *
                (coupon.discountPercent / 100)
            }
          })
        }
        return {
          name: coupon.description || coupon.name,
          price: parseFloat(-couponDiscountAmount).toFixed(2),
        }
      })
      .filter(x => !isNaN(x.price)),
  ]

  const setItems = () => {
    return getAddonsList().map(({name, price}, index) => (
      <div className="d-flex justify-content-between" key={index}>
        <ParagraphText variant="sm">{name}</ParagraphText>
        <ParagraphText variant="sm">{price}</ParagraphText>
      </div>
    ))
  }

  return (
    <div className="dropdown">
      <button
        className="btn dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        onClick={handleToggleDropdown}
        style={{
          outline: 'none',
          boxShadow: 'none',
          fontSize: '20px',
          fontFamily: 'Open Sans,sans-serif',
        }}
        onBlur={() => setToggleDropdown(false)}
      >
        {title}
      </button>
      <div
        className={`dropdown-menu p-4 ${toggleDropdown ? 'show' : ''}`}
        aria-labelledby="dropdownMenuButton"
        style={{minWidth: '350px'}}
      >
        {setItems()}
      </div>
    </div>
  )
}

const OldFlowDesktop = ({
  currentStep,
  paymentState,
  isLoading,
  todayItems,
  handleChangePaymentMethod,
  paymentMethods,
  calculateTaxes,
  calculatingTaxes,
  setCalculatingTaxes,
  checkBoxesValues,
  handleOnCheckBoxChange,
  setName,
  setIsAddressUpdated,
  uuid,
  couponDiscountAmount,
  totalNoTaxes,
  calculatedTaxes,
  isUpdatePaymentButtonDisabled,
  handleCompleteOrder,
  name,
  isAddressUpdated,
  setAddressInformation,
  addressInformation,
  updateParentAddress,
}) => {
  const hasSupportFee =
    paymentState.monthlyAddons.some(({code}) => code === '247support-m2m') ||
    todayItems.some(({code}) => code === '247support-m2m')
  const supportFee =
    paymentState.monthlyAddons.find(({code}) => code === '247support-m2m') ||
    todayItems.find(({code}) => code === '247support-m2m')
  const isAus = paymentState.paymentInfo?.country === 'AU'
  const isInvoiceBilled = paymentState.paymentInfo?.isInvoiceBilled
  const [isBillingNoticeDisplayed, setIsBillingNoticeDisplayed] =
    React.useState(false)
  console.log(
    'isBillingNoticeDisplayed from old-flow-desktop:: ',
    isBillingNoticeDisplayed,
  )
  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={currentStep === 2 ? {height: '90vh'} : {}}
    >
      <div>
        <BillingNoticeModal
          setIsBillingNoticeDisplayed={setIsBillingNoticeDisplayed}
          calledFromPaymentLink={true}
        />
      </div>
      <MainContainer className={`p-4 ${isAus && currentStep === 1 && 'pb-5'}`}>
        {isLoading ? (
          <SkeletonLoader width="100%" height="80vh" />
        ) : (
          <>
            {currentStep === 1 && (
              <>
                <div className="d-flex justify-content-between mb-4">
                  <img src={thryvSalesLogo} alt="Thryv Sales Logo" />
                </div>
                <ParagraphText variant="lg" className="mt-2">
                  Enter your payment information to finish processing your Thryv
                  order.
                </ParagraphText>
                <ParagraphText
                  variant="reg"
                  className="m-0 mt-2 font-weight-bold"
                >
                  {paymentState.paymentInfo.plan.planUnit === 'semi-annually'
                    ? 'Semi-Annually '
                    : 'Monthly '}
                  Total
                </ParagraphText>
                <DropdownProducts
                  paymentState={paymentState}
                  title={`${formatPrice(paymentState.monthlyAmount)}`}
                  todayItems={todayItems}
                />
                <ParagraphText
                  variant="lg"
                  className="m-0 mt-2 mb-5 font-weight-bold total-price"
                ></ParagraphText>
                {paymentState.paymentInfo.country === 'US' &&
                  !paymentState?.paymentInfo?.isInvoiceBilled && (
                    <>
                      <ParagraphText
                        variant="lg"
                        className="font-weight-bold mt-4"
                      >
                        Select Payment Method
                      </ParagraphText>
                      <PaymentMethod
                        paymentMethod={paymentState.paymentMethod}
                        handleChangePaymentMethod={handleChangePaymentMethod}
                        paymentMethods={paymentMethods}
                        isDesktop={true}
                      />
                    </>
                  )}
                {!!paymentState?.paymentInfo?.isInvoiceBilled && (
                  <InvoicePaymentForm
                    calculateTaxes={calculateTaxes}
                    calculatingTaxes={calculatingTaxes}
                    setCalculatingTaxes={setCalculatingTaxes}
                    checkBoxesValues={checkBoxesValues}
                    handleOnCheckBoxChange={handleOnCheckBoxChange}
                    businessAddress={paymentState.paymentInfo?.businessAddress}
                    country={paymentState?.paymentInfo?.country}
                    setName={setName}
                    handleCompleteOrder={handleCompleteOrder}
                    setIsAddressUpdated={setIsAddressUpdated}
                    bizphone={paymentState.paymentInfo?.businessAddress?.phone}
                    is5Order={false}
                    isAddressUpdated={isAddressUpdated}
                    uuid={uuid}
                    addressInformation={addressInformation}
                    setAddressInformation={setAddressInformation}
                    updateParentAddress={updateParentAddress}
                  />
                )}
                {paymentState.paymentMethod === paymentMethods[0] &&
                  !paymentState?.paymentInfo?.isInvoiceBilled && (
                    <PaymentForm
                      calculateTaxes={calculateTaxes}
                      calculatingTaxes={calculatingTaxes}
                      setCalculatingTaxes={setCalculatingTaxes}
                      checkBoxesValues={checkBoxesValues}
                      handleOnCheckBoxChange={handleOnCheckBoxChange}
                      businessAddress={
                        paymentState?.paymentInfo?.businessAddress
                      }
                      country={paymentState?.paymentInfo?.country}
                      setName={setName}
                      handleCompleteOrder={handleCompleteOrder}
                      setIsAddressUpdated={setIsAddressUpdated}
                      bizphone={
                        paymentState.paymentInfo?.businessAddress?.phone
                      }
                    />
                  )}
                {paymentState.paymentMethod === paymentMethods[1] &&
                  !paymentState?.paymentInfo?.isInvoiceBilled && (
                    <AchPaymentForm
                      uuid={uuid}
                      calculateTaxes={calculateTaxes}
                      setCalculatingTaxes={setCalculatingTaxes}
                      checkBoxesValues={checkBoxesValues}
                      handleOnCheckBoxChange={handleOnCheckBoxChange}
                      businessAddress={
                        paymentState?.paymentInfo?.businessAddress
                      }
                      setName={setName}
                      setIsAddressUpdated={setIsAddressUpdated}
                    />
                  )}

                <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-4">
                  <ParagraphText
                    variant="lg"
                    color="thryv-black-500"
                    className="font-weight-bold"
                  >
                    Sub Total (
                    {isInvoiceBilled ? 'Due Next Invoice' : 'Due Today'})
                  </ParagraphText>
                  <ParagraphText
                    variant="lg"
                    color="thryv-black-500"
                    className="font-weight-bold"
                  >
                    ${' '}
                    {Math.max(
                      paymentState.totalPaymentAmountNoTaxes -
                        couponDiscountAmount +
                        (paymentState?.paymentInfo?.balanceInfo
                          ?.availableCreditAmount || 0),
                      0,
                    ).toFixed(2)}
                  </ParagraphText>
                </div>
                <hr />
                {paymentState?.paymentInfo?.balanceInfo
                  ?.availableCreditAmount && (
                  <>
                    <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        Credit
                      </ParagraphText>
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        {formatPrice(
                          paymentState?.paymentInfo?.balanceInfo
                            ?.availableCreditAmount || 0,
                        )}
                      </ParagraphText>
                    </div>
                    <hr />
                  </>
                )}
                {paymentState.monthlyAmount > 0 && (
                  <>
                    <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        Monthly Total
                      </ParagraphText>
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        {formatPrice(paymentState.monthlyAmount)}
                      </ParagraphText>
                    </div>
                    <hr />
                  </>
                )}
                {paymentState.semiAnnuallyAmount > 0 && (
                  <>
                    <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        Semi-Annually Total
                      </ParagraphText>
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        $ {paymentState.semiAnnuallyAmount}
                      </ParagraphText>
                    </div>
                    <hr />
                  </>
                )}
                {!isAus && (
                  <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                    <ParagraphText
                      variant="lg"
                      color="thryv-black-500"
                      className="font-weight-bolder"
                    >
                      {paymentState.calculatedTaxes > 0
                        ? 'Tax'
                        : 'Tax (to be calculated)'}
                    </ParagraphText>
                    <ParagraphText
                      variant="lg"
                      color="thryv-black-500"
                      className="font-weight-bolder"
                    >
                      {paymentState.calculatedTaxes > 0
                        ? `$ ${paymentState.calculatedTaxes.toFixed(2)}`
                        : '-'}
                    </ParagraphText>
                  </div>
                )}
                {hasSupportFee && (
                  <>
                    <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2">
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >
                        24x7 Service & Support Fee
                      </ParagraphText>
                      <ParagraphText
                        variant="lg"
                        color="thryv-black-500"
                        className="font-weight-bolder"
                      >{`$ ${supportFee.currency.unitAmount.toFixed(
                        2,
                      )}`}</ParagraphText>
                    </div>
                    {isAus && <hr />}
                  </>
                )}
                {!isAus && <hr />}
                <div className="d-flex justify-content-between p-0 pr-4 pl-4 m-0 mt-2 mb-2">
                  <ParagraphText
                    variant="lg"
                    color="thryv-black-500"
                    className="font-weight-bold"
                  >
                    Total ({isInvoiceBilled ? 'Due Next Invoice' : 'Due Today'})
                    {totalNoTaxes}
                    {calculatedTaxes}
                    {couponDiscountAmount}
                  </ParagraphText>
                  <ParagraphText
                    variant="lg"
                    color="thryv-black-500"
                    className="font-weight-bold"
                  >
                    ${' '}
                    {paymentState.calculatedTaxes > 0 && !isAus
                      ? Math.max(
                          Number(totalNoTaxes) +
                            calculatedTaxes -
                            couponDiscountAmount,
                          0,
                        ).toFixed(2)
                      : Math.max(
                          Number(totalNoTaxes) - couponDiscountAmount,
                          0,
                        ).toFixed(2)}{' '}
                    {!paymentState.calculatedTaxes && !isAus && ' + taxes'}
                  </ParagraphText>
                </div>
                <div>
                  {paymentState.paymentInfo?.country === 'AU' && (
                    <div>
                      <ParagraphText
                        variant="sm"
                        className="font-weight-bold mt-4
      "
                      >
                        + Applicable Fees
                      </ParagraphText>
                      <ParagraphText
                        variant="sm"
                        className="font-weight-bold mt-4
        "
                      >
                        *All plans include GST
                      </ParagraphText>
                    </div>
                  )}
                </div>
                <div className="mt-4 mb-4">
                  <ParagraphText variant="sm" style={{fontSize: '14px'}}>
                    *This initial Subscription Period is 6 months
                  </ParagraphText>
                  <Checkbox
                    hasLabel
                    name="terms"
                    value={checkBoxesValues.agreedTerms}
                    defaultChecked={checkBoxesValues.agreedTerms}
                    onChange={handleOnCheckBoxChange}
                    label={
                      <label style={{marginTop: '5px'}}>
                        By checking this box, you agree to our{' '}
                        <a
                          target="_blank"
                          href="https://corporate.thryv.com/terms/"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    }
                  />
                  <ParagraphText variant="sm">
                    By processing your payment, you agree that your personal
                    information will be shared with Thryv’s payment
                    processor—Adyen, N.V. and will be subject to Adyen’s{' '}
                    <a
                      target="_blank"
                      href="https://www.adyen.com/policies-and-disclaimer/privacy-policy"
                      rel="noreferrer"
                    >
                      privacy statement
                    </a>
                    .
                  </ParagraphText>
                </div>
                {!isUpdatePaymentButtonDisabled() && (
                  <div className="m-3 d-flex justify-content-center">
                    <Button
                      variant="primary"
                      type="button"
                      disabled={isUpdatePaymentButtonDisabled()}
                      onClick={() => {
                        if (paymentState.paymentInfo.isInvoiceBilled) {
                          updateParentAddress(
                            addressInformation,
                            paymentState.paymentInfo.businessAddress.phone,
                            handleCompleteOrder,
                          )
                        } else {
                          handleCompleteOrder()
                        }
                      }}
                    >
                      Complete Purchase
                    </Button>
                  </div>
                )}
              </>
            )}
            {currentStep === 2 && !calculatingTaxes && (
              <div className="d-flex flex-column align-items-center">
                <Icon
                  style={{marginBottom: '25px'}}
                  type="solid"
                  variant="circleCheck"
                  color="#5cb95c"
                  width="48px"
                  height="48px"
                />
                <ParagraphText variant="lg">
                  <strong>
                    Thank you {name}, your payment information is successfully
                    updated!
                  </strong>
                </ParagraphText>
                <ParagraphText variant="lg">
                  We will use this information to bill your Thryv subscriptions
                  each month.
                </ParagraphText>
                <ParagraphText variant="lg">
                  You can update your billing information through the My Account
                  page.
                </ParagraphText>
                <ParagraphText variant="lg">
                  <strong>
                    You are now ready to complete your Thryv Purchase!
                  </strong>
                </ParagraphText>
              </div>
            )}
          </>
        )}
      </MainContainer>
    </div>
  )
}

export default OldFlowDesktop
