import styled from '@emotion/styled'

export const CopyLinkContainer = styled.div`
  position: relative;
  overflow: hidden;
  margin: 25px 0px 40px;
  .url-wrapper {
    width 100%;
    height: 50px;
    font-size: 15px;
    font-family: 'Montserrat' sans-serif;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  .g-url {
    padding: 12px 0 12px 12px;
    width: 100%;
  }
  .btn-gurl {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 10px 12px;
    borderRadius: 0 4px 4px 0;
    cursor: pointer;
    fontSize: 14px;
  }
  .btn-gurl:hover {
    font-weight: bold !important;
    outline: 1px solid #8c8b8b;
    }
`
