/**
@jsxRuntime
classic */
/** @jsx jsx */
import {jsx} from '@emotion/core'
import {Routes, Route} from 'react-router-dom'
import * as Sentry from '@sentry/react'

import {HeaderTitle} from './components/header-title'
import NotFoundPage from './components/not-found-page'
import {
  ErrorContainer,
  TextContainer,
  ImageContainer,
  ItemContainer,
  ScreenContainer,
} from './components/ui/error-fallback'
import {thryvGuyConfused} from './assets'
import {ParagraphText, Button} from '@thryvlabs/maverick'
import {HomeKeapPartner} from './screens/keap-partner/home'
// import {useFlags} from 'launchdarkly-react-client-sdk'

function FullPageErrorFallback({error}) {
  const handleClick = () => window.location.reload()

  return (
    <ScreenContainer>
      <ErrorContainer>
        <ItemContainer>
          <TextContainer>
            <ParagraphText
              variant="reg"
              style={{
                color: '#ff5101',
                fontWeight: 'bold',
                fontSize: '2rem',
                marginBottom: '20px',
              }}
            >
              Uh Oh!
            </ParagraphText>
            <ParagraphText
              variant="reg"
              className="m-0"
              style={{fontWeight: 'bold', fontSize: '1rem'}}
            >
              There&apos;s a problem. Try refreshing the app.
            </ParagraphText>
          </TextContainer>
          <Button
            onClick={handleClick}
            variant="primary"
            style={{marginTop: '50px'}}
          >
            Refresh Now
          </Button>
        </ItemContainer>
        <ImageContainer>
          <img src={thryvGuyConfused} alt="confused Thryv Guy" />
        </ImageContainer>
      </ErrorContainer>
    </ScreenContainer>
  )
}

function AuthenticatedKeapPartnerApp() {
  //   const lDFlags = useFlags()
  return (
    <Sentry.ErrorBoundary fallback={FullPageErrorFallback}>
      <Routes>
        <Route
          path="/"
          element={
            <HeaderTitle title="Keap Partner Home">
              <HomeKeapPartner />
            </HeaderTitle>
          }
        />
        <Route
          path="*"
          element={
            <HeaderTitle title="404 - Not Found">
              <NotFoundPage />
            </HeaderTitle>
          }
        />
      </Routes>
    </Sentry.ErrorBoundary>
  )
}

export default AuthenticatedKeapPartnerApp
