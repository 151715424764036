import styled from '@emotion/styled'

export const KeapPartnerHomeWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100%;
  position: relative;
`

export const KeapPartnerMainContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.5rem;
  padding: 1.5rem;
  width: 100%;
  flex-grow: 1;
  align-items: center;
`

export const KeapPartnerTitle = styled.h1`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 2rem;
  font-family: 'Open Sans', sans-serif;
  text-transform: capitalize;
`

export const KeapPartnerHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1442px;
`

export const KeapPartnerSection = styled.section`
  border: 1px solid rgba(222, 226, 230, 50%);
  padding: 1.5rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  width: 100%;
  max-width: 1442px;
`

export const KeapPartnerSectionTitle = styled.h2`
  margin: 0;
  font-weight: 600;
  font-size: 1.5rem;
  font-family: 'Open Sans', sans-serif;
`

export const KeapPartnerOrangeLink = styled.a`
  color: #ff5000;
`

export const ThryvColorSpan = styled.span`
  color: #ff5000;
`

export const KeapPartnerArticle = styled.article`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  padding-block: 1rem;
`

export const KeapPartnerSectionParagraph = styled.p`
  margin: 0;
  font-weight: 400;
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
`

export const KeapPartnerImagesContainer = styled.div`
  display: flex;
  column-gap: 1rem;
  width: 100%;
  padding: 1rem;
`

export const KeapPartnerImageLink = styled.a`
  display: flex;
  flex-grow: 1;
  height: auto;
  width: auto;
`

export const KeapPartnerImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain;
`

export const KeapPartnerSidebarContainer = styled.aside`
  display: flex;
  flex-direction: column;
  height: calc(100dvh - 4rem);
  border-left: 1px solid rgba(222, 226, 230, 50%);
  padding: 1.5rem;
  row-gap: 1rem;
  position: sticky;
  right: 0;
  top: 4rem;
  bottom: 0;
`

export const KeapPartnerSidebarFooter = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-block-end: 3.5rem;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`

export const KeapPartnerSidebarSubtitle = styled.h3`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 1.25rem;
  font-family: 'Open Sans', sans-serif;
`

export const KeapPartnerSidebarMutedText = styled.p`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 1.125rem;
  font-family: 'Open Sans', sans-serif;
  color: rgb(129, 129, 129);
`

export const KeapPartnerSidebarMutedLink = styled.a`
  margin: 0;
  width: max-content;
  font-weight: 600;
  font-size: 1.125rem;
  font-family: 'Open Sans', sans-serif;
  color: rgb(129, 129, 129);

  &:hover {
    color: rgb(129, 129, 129) !important;
  }
`

export const KeapPartnerSidebarNav = styled.nav`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const KeapPartnerSidebarLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 0.25rem;
  width: 100%;
  min-width: max-content;
  padding-inline: 1rem;
  padding-block: 0.25rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;

  background-color: rgba(9, 101, 243, 0.11);
  color: rgb(7, 126, 244);
  &:hover {
    color: rgb(7, 126, 244);
  }
`

export const KeapPartnerSidebarKeapLink = styled(KeapPartnerSidebarLink)`
  background-color: #b3e6b2;
  color: #1a4d19;

  & div {
    filter: brightness(50%);
  }

  &:hover {
    color: #359b31;
  }

  &:hover div {
    display: flex;
    position: relative;
    filter: brightness(100%);
  }

  &:hover div::after {
    content: '';
    position: absolute;
    width: 120%;
    height: 0.09rem;
    background-color: #359b31;
    left: 0;
    bottom: 0.1rem;
    right: 0;
  }
`

export const KeapPartnerSidebarThryvLink = styled(KeapPartnerSidebarLink)`
  background-color: #fde3d7;
  color: #000;

  &:hover {
    color: #000;
  }

  &:hover div {
    display: flex;
    position: relative;
  }

  &:hover div::after {
    content: '';
    position: absolute;
    width: 120%;
    height: 0.09rem;
    background-color: #000;
    left: 0;
    bottom: 0.1rem;
    right: 0;
  }
`

export const KeapPartnerLogo = styled.div`
  width: 40.75px;
  height: 1.25rem;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
`

export const ThryvLogoForResourceLink = styled.div`
  width: 40.75px;
  height: 1.25rem;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
`

export const KeapPartnerSidebarDivider = styled.div`
  padding-block: 1rem;
`

export const KeapMCIconList = styled.ul`
  display: flex;
  column-gap: 1rem;
  width: 100%;
  margin: 0;
  padding: 1rem 0 0 0;
`

export const KeapMCIconItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0.25rem;
  flex-grow: 1;
  width: 100%;
`

export const KeapMcIconImage = styled.img`
  min-width: 4rem;
  max-width: 4rem;
  aspect-ratio: 1;
  object-fit: contain;
`

export const KeapMCIconText = styled.p`
  width: 100%;
  margin: 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 600;
  text-decoration: none;
  color: #000;
  line-height: 110%;
`

export const KeapMcAttributesContainer = styled.div`
  flex-grow: 2;
  display: flex;
  column-gap: 1rem;
  justify-content: center;
  align-items: center;
`

export const KeapMcAttributesItem = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex-grow: 1;
  row-gap: 1rem;
  max-width: 13.75rem;
`

export const KeapMcAttributesItemHeader = styled.header`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  row-gap: 0.75rem;
`

export const KeapMcAttributesItemImage = styled.img`
  width: 3.75rem;
  height: auto;
  aspect-ratio: 1;
`

export const KeapMcAttributesTextsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
`

export const KeapMcAttributesItemLabel = styled.h4`
  margin: 0;
  font-size: 0.75rem;
  font-weight: 600;
  color: #ff5000;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
`

export const KeapMcAttributesItemTitle = styled.p`
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  font-family: 'Open Sans', sans-serif;
  text-wrap: balance;
  text-align: center;
  line-height: 110%;
  font-stretch: expanded;
`

export const KeapMcAttributesBulletList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  row-gap: 0.75rem;
  width: 100%;
`

export const KeapMcAttributesBulletItem = styled.li`
  margin: 0;
  display: flex;
  column-gap: 0.5rem;

  & svg {
    min-width: 1rem;
    max-width: 1rem;
    min-height: 1rem;
    max-height: 1rem;
  }
`

export const KeapMcAttributesBulletItemText = styled.span`
  font-size: 0.75rem;
  color: rgb(129, 129, 129);
  line-height: 120%;
`
