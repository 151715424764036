import React from 'react'
import SkeletonLoader from '../skeleton-loader'
import {Button, ParagraphText, Checkbox, Icon} from '@thryvlabs/maverick'

import {PaymentDetailsContainer} from '../ui/payment-information'
import {thryvLogo} from '../../assets'
import {PaymentForm} from './recurly-payment-form'
import {PaymentMethod} from './payment-method'
import {AchPaymentForm} from './ach-payment-form'
import {InvoicePaymentForm} from './invoice-payment-form'
import {MainContainer} from '../ui/command-center-layout'
import {CCDesktopTodaySummary} from './cc-desktop-today-summary'
import {CCDesktopMonthlySummary} from './cc-desktop-monthly-summary'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {getTodayPreviewItems} from '../../utils/get-today-preview-items'
import {BillingNoticeModal} from '../../components/billing-notice-modal'

const ONE_TIME_FEES = [
  '5 extra website pages',
  'onboarding fee',
  'website design fee',
]

const CCDesktop = ({
  isLoading,
  currentStep,
  paymentState,
  handleChangePaymentMethod,
  paymentMethods,
  is5Order,
  calculateTaxes,
  calculatingTaxes,
  setCalculatingTaxes,
  checkBoxesValues,
  handleOnCheckBoxChange,
  setName,
  setIsAddressUpdated,
  isAddressUpdated,
  uuid,
  todayItems,
  totalNoTaxes,
  couponDiscountAmount,
  isUpdatePaymentButtonDisabled,
  handleCompleteOrder,
  name,
  addressInformation,
  setAddressInformation,
  updateParentAddress,
}) => {
  const {frontendPreviewPayment} = useFlags()
  const [isBillingNoticeDisplayed, setIsBillingNoticeDisplayed] =
    React.useState(false)
  console.log('isBillingNoticeDisplayed :: ', isBillingNoticeDisplayed)
  const paymentPreview = paymentState.paymentInfo?.paymentPreview
  const isAusNZ = ['AU', 'NZ'].includes(paymentState.paymentInfo?.country)
  const hasSupportFee =
    !frontendPreviewPayment || !paymentPreview
      ? todayItems.some(({code}) => code === '247support-m2m')
      : paymentPreview.apps.some(({planCode}) => planCode === '247support-m2m')
  const supportFee = todayItems.find(({code}) => code === '247support-m2m')
  const rankItem = plan => {
    let planName = plan?.sorting?.toLowerCase() || plan.name.toLowerCase()

    if (planName.includes('command')) return 1
    if (planName.includes('business')) return 2
    if (planName.includes('marketing')) return 3
    if (ONE_TIME_FEES.includes(planName.toLowerCase())) return 5
    if (plan.isCoupon) return 6
    return 4
  }

  const itemComparator = (a, b) => {
    const rankA = rankItem(a)
    const rankB = rankItem(b)
    return rankA - rankB
  }
  const getProducts = (items, includeCoupons = false) => {
    if (!includeCoupons) {
      return [...items]
    }

    const formattedCoupons = paymentState.paymentInfo.coupons.map(coupon => {
      let couponDiscountAmount = 0

      if (coupon.discountAmount) {
        couponDiscountAmount = coupon.discountAmount
      } else if (coupon.isPercentDiscount && coupon.appliesToAllPlans) {
        todayItems.forEach(addon => {
          couponDiscountAmount +=
            addon.currency.unitAmount *
            addon.quantity *
            (coupon.discountPercent / 100)
        })
      } else {
        todayItems.forEach(addon => {
          if (coupon?.appliesToPlans?.includes(addon.code)) {
            couponDiscountAmount +=
              addon.currency.unitAmount *
              addon.quantity *
              (coupon.discountPercent / 100)
          }
        })
      }

      const formattedCoupon = {
        name: coupon.description || coupon.name,
        code: coupon.code,
        isCoupon: true,
        currency: {
          unitAmount: couponDiscountAmount,
        },
        appliesToPlans: coupon.appliesToPlans,
      }

      return formattedCoupon
    })

    const filteredCoupons = formattedCoupons.filter(
      x => !isNaN(x.currency.unitAmount),
    )

    items = items.flatMap(current => {
      const currentPrice = Number(current.currency.unitAmount)
      const isCohort = paymentState?.paymentInfo?.isCohort
      if (!isCohort && currentPrice > 0) {
        let price = 0
        if (paymentPreview) {
          price = current.discount
        } else {
          if (paymentState?.paymentInfo?.coupons) {
            let couponAmount = 0
            for (const coupon of paymentState.paymentInfo.coupons) {
              if (coupon.appliesToPlans?.includes(current?.code)) {
                if (coupon.discountAmount > 0)
                  couponAmount += coupon.discountAmount
                else if (coupon.discountPercent > 0) {
                  couponAmount +=
                    (current.quantity || 1) *
                    current.currency?.unitAmount *
                    (coupon.discountPercent / 100)
                }
              } else if (coupon.appliesToAllPlans === true) {
                if (coupon.discountAmount > 0)
                  couponAmount += coupon.discountAmount
                else if (coupon.discountPercent > 0)
                  couponAmount +=
                    (current.quantity || 1) *
                    current.currency?.unitAmount *
                    (coupon.discountPercent / 100)
              }
            }
            if (
              couponAmount >
              (current.quantity || 1) * current.currency?.unitAmount
            ) {
              couponAmount =
                (current.quantity || 1) * current.currency?.unitAmount
            }
            price = Math.max(couponAmount, 0)
          }
        }
        if (price > 0) {
          return [
            current,
            {
              name: 'Total Incentive',
              currency: {
                unitAmount: price,
              },
              isIncentive: true,
              sorting: current.name,
            },
          ]
        }
      }
      return current
    })

    return [...items, ...filteredCoupons]
  }

  return (
    <div style={{maxWidth: '1077px', margin: '20px auto 0 auto'}}>
      {paymentState?.paymentInfo?.flow === 'upgrade' && (
        <div>
          <BillingNoticeModal
            setIsBillingNoticeDisplayed={setIsBillingNoticeDisplayed}
            calledFromPaymentLink={true}
          />
        </div>
      )}
      <MainContainer
        columnAmount={isLoading || currentStep === 2 ? 1 : 2}
        customPadding="0"
      >
        {isLoading ? (
          <SkeletonLoader width="100%" height="80vh" />
        ) : (
          <>
            {currentStep === 1 && (
              <>
                <div
                  style={{
                    maxWidth: '538px',
                    borderRight: '1px solid #E3E6E8',
                    paddingRight: '15px',
                  }}
                >
                  <div
                    className="d-flex justify-content-between"
                    style={{marginBottom: '16px'}}
                  >
                    <img
                      src={thryvLogo}
                      alt="Thryv Sales Logo"
                      width="92"
                      height="44px"
                    />
                  </div>
                  {paymentState.paymentInfo.country === 'US' &&
                    !paymentState?.paymentInfo?.isInvoiceBilled && (
                      <>
                        <ParagraphText
                          variant="lg"
                          className="font-weight-bold"
                          style={{marginBottom: '12px'}}
                        >
                          Select Payment Method
                        </ParagraphText>
                        <PaymentMethod
                          paymentMethod={paymentState.paymentMethod}
                          handleChangePaymentMethod={handleChangePaymentMethod}
                          paymentMethods={paymentMethods}
                          is5Order={is5Order}
                          isDesktop={true}
                        />
                      </>
                    )}
                  {paymentState?.paymentInfo?.isInvoiceBilled && (
                    <InvoicePaymentForm
                      calculateTaxes={calculateTaxes}
                      calculatingTaxes={calculatingTaxes}
                      setCalculatingTaxes={setCalculatingTaxes}
                      checkBoxesValues={checkBoxesValues}
                      handleOnCheckBoxChange={handleOnCheckBoxChange}
                      businessAddress={
                        paymentState.paymentInfo?.businessAddress
                      }
                      country={paymentState?.paymentInfo?.country}
                      setName={setName}
                      handleCompleteOrder={handleCompleteOrder}
                      setIsAddressUpdated={setIsAddressUpdated}
                      bizphone={
                        paymentState.paymentInfo?.businessAddress?.phone
                      }
                      is5Order={is5Order}
                      isAddressUpdated={isAddressUpdated}
                      uuid={uuid}
                      addressInformation={addressInformation}
                      setAddressInformation={setAddressInformation}
                      updateParentAddress={updateParentAddress}
                    />
                  )}
                  {paymentState.paymentMethod === paymentMethods[0] &&
                    !paymentState?.paymentInfo?.isInvoiceBilled && (
                      <PaymentForm
                        calculateTaxes={calculateTaxes}
                        calculatingTaxes={calculatingTaxes}
                        setCalculatingTaxes={setCalculatingTaxes}
                        checkBoxesValues={checkBoxesValues}
                        handleOnCheckBoxChange={handleOnCheckBoxChange}
                        businessAddress={
                          paymentState.paymentInfo?.businessAddress
                        }
                        country={paymentState?.paymentInfo?.country}
                        setName={setName}
                        handleCompleteOrder={handleCompleteOrder}
                        setIsAddressUpdated={setIsAddressUpdated}
                        bizphone={
                          paymentState.paymentInfo?.businessAddress?.phone
                        }
                        is5Order={is5Order}
                        isAddressUpdated={isAddressUpdated}
                        paymentPreview={paymentPreview}
                      />
                    )}
                  {paymentState.paymentMethod === paymentMethods[1] &&
                    !paymentState?.paymentInfo?.isInvoiceBilled && (
                      <AchPaymentForm
                        uuid={uuid}
                        calculateTaxes={calculateTaxes}
                        setCalculatingTaxes={setCalculatingTaxes}
                        checkBoxesValues={checkBoxesValues}
                        handleOnCheckBoxChange={handleOnCheckBoxChange}
                        businessAddress={
                          paymentState?.paymentInfo?.businessAddress
                        }
                        setName={setName}
                        setIsAddressUpdated={setIsAddressUpdated}
                        is5Order={is5Order}
                      />
                    )}
                  <ParagraphText
                    variant="sm"
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      fontSize: '14px',
                    }}
                  >
                    *This initial Subscription Period is 6 months
                  </ParagraphText>
                  <Checkbox
                    hasLabel
                    name="terms"
                    value={checkBoxesValues.agreedTerms}
                    defaultChecked={checkBoxesValues.agreedTerms}
                    onChange={handleOnCheckBoxChange}
                    label={
                      <label>
                        By checking this box, you agree to our{' '}
                        <a
                          target="_blank"
                          href="https://corporate.thryv.com/terms/"
                          rel="noreferrer"
                        >
                          Terms & Conditions
                        </a>
                      </label>
                    }
                  />
                  <ParagraphText variant="sm">
                    By processing your payment, you agree that your personal
                    information will be shared with Thryv’s payment
                    processor—Adyen, N.V. and will be subject to Adyen’s{' '}
                    <a
                      target="_blank"
                      href="https://www.adyen.com/policies-and-disclaimer/privacy-policy"
                      rel="noreferrer"
                    >
                      privacy statement
                    </a>
                    .
                  </ParagraphText>
                </div>

                <div style={{maxWidth: '538px', paddingLeft: '15px'}}>
                  <ParagraphText
                    variant="lg"
                    style={{fontSize: '18px', fontWeight: 'bold'}}
                  >
                    Payment Details
                  </ParagraphText>
                  <PaymentDetailsContainer>
                    <CCDesktopMonthlySummary
                      getProducts={getProducts}
                      paymentState={paymentState}
                      itemComparator={itemComparator}
                      isAusNZ={isAusNZ}
                      hasSupportFee={supportFee}
                      supportFee={supportFee}
                    />

                    <div
                      style={{border: '1px dashed #A3A5A7', margin: '12px 0'}}
                    />

                    <CCDesktopTodaySummary
                      todayItems={
                        !frontendPreviewPayment || !paymentPreview
                          ? todayItems
                          : getTodayPreviewItems({paymentState, todayItems})
                      }
                      getProducts={getProducts}
                      itemComparator={itemComparator}
                      isAusNZ={isAusNZ}
                      hasSupportFee={hasSupportFee}
                      paymentState={paymentState}
                      supportFee={supportFee}
                      totalNoTaxes={totalNoTaxes}
                      couponDiscountAmount={couponDiscountAmount}
                    />
                  </PaymentDetailsContainer>
                </div>

                {paymentState.paymentInfo.isInvoiceBilled || (
                  <div
                    className="d-flex justify-content-center mt-3"
                    style={{gridRow: 2, gridColumn: 2}}
                  >
                    <Button
                      variant="primary"
                      type="button"
                      disabled={isUpdatePaymentButtonDisabled()}
                      onClick={() => {
                        if (paymentState.paymentInfo.isInvoiceBilled) {
                          updateParentAddress(
                            addressInformation,
                            paymentState.paymentInfo.businessAddress.phone,
                            handleCompleteOrder,
                          )
                        } else {
                          handleCompleteOrder()
                        }
                      }}
                    >
                      Complete Purchase
                    </Button>
                  </div>
                )}
              </>
            )}
            {currentStep === 2 && !calculatingTaxes && (
              <div
                className="d-flex flex-column align-items-center"
                style={{maxWidth: '500px', margin: '0 auto', rowGap: '20px'}}
              >
                <Icon
                  type="solid"
                  variant="circleCheck"
                  color="#16A085"
                  width="48px"
                  height="48px"
                />
                <ParagraphText
                  variant="lg"
                  className="m-0"
                  style={{
                    color: '#16A085',
                    fontSize: '16px',
                    textAlign: 'center',
                  }}
                >
                  <strong>
                    Thank you {name}, your payment information is <br />{' '}
                    successfully updated!
                  </strong>
                </ParagraphText>
                <ParagraphText variant="lg" className="m-0">
                  We will use this information to bill your Thryv subscriptions
                  each month.
                </ParagraphText>
                <ParagraphText variant="lg" className="m-0">
                  You can update your billing information through the My Account
                  page.
                </ParagraphText>
                <ParagraphText variant="lg" className="w-100 m-0">
                  <strong>
                    You are now ready to complete your Thryv Purchase!
                  </strong>
                </ParagraphText>
              </div>
            )}
          </>
        )}
      </MainContainer>
    </div>
  )
}

export default CCDesktop
