import React from 'react'

import {GenerateBuyNowLinkButton} from './generate-buy-now-link-button'
import {GenerateBuyNowLinkModal} from './generate-buy-now-link-modal'
import {GenerateBuyNowLinkProvider} from './generate-buy-now-link-provider'

/**
 *
 * @param {{
 *    keapPartnerId: string,
 *    sfPartnerAccountId: string,
 *    partnerEmail: string,
 *    partnerFirstName: string,
 *    partnerLastName: string,
 * }} props
 * @returns
 */

export function GenerateBuyNowLink({userData}) {
  return (
    <GenerateBuyNowLinkProvider userData={userData}>
      <GenerateBuyNowLinkButton />
      <GenerateBuyNowLinkModal />
    </GenerateBuyNowLinkProvider>
  )
}
