import {useState, useEffect} from 'react'
import {useAuthClient} from './use-auth-client'
import {useLocation} from 'react-router'
import {MC_PLANS, BC_PLANS, RC_PLANS} from './constants'
import {ADD_ON} from './addons-data'

const WIN_BACK_4_COUPONS = {
  'plus-4-dexyp-m2m': 'pluswinback2022',
  'unlimited-4-dexyp-m2m': 'unlimitedwinback2022',
  'professional-4-dexyp-m2m': 'professionalwinback2022',
}

function useAutoCoupons({
  selectedPackage,
  selectedIndustryId,
  selectedCountry,
  selectedCentersAndApps,
  defaultCentersAndApps,
  expiredCentersAndApps,
  eligibleTransitionCenters,
  selectedAddons,
  upgradeAccountInfo,
  incentiveCoupon,
  setIncentiveCoupon,
  currentStep,
  setOrderCouponCodes,
  ccCouponInfo,
  upgradePaymentPreview,
  isEaidFound,
  isCohort,
  clientType,
  defaultCouponInfo,
}) {
  const [autoCouponInfo, setAutoCouponInfo] = useState([])
  const [appliedCoupons, setAppliedCoupons] = useState(false)
  const client = useAuthClient()
  const {pathname} = useLocation()

  console.log('use-auto-coupon.js ccCouponInfo :: ', ccCouponInfo)

  const isNewOrder =
    pathname === '/order/new' &&
    selectedIndustryId !== 13 &&
    selectedIndustryId !== 12
  const isUpgrade = pathname === '/order/upgrade'
  const selectedPlan = selectedPackage || 'appmarket-5-thryv-m2m'
  const is5Plan = selectedPlan.includes('-5-')
  const FREE_ACCOUNT_CODES = [
    'appmarket-5-thryv-m2m',
    'cc_basicpls-5-thryv-m2m',
    'cc_basic-5-thryv-m2m',
    'addon-5-signatures_usage',
    'app-websitebuilder',
  ]
  const MC_PLAN_CODES = ['addon-4-mc-m2m', 'addon-4-mcpro-m2m']
  const findSensisSiteDomain = () => {
    if (selectedCentersAndApps) {
      return selectedCentersAndApps.apps.find(app => app.sensisSiteDomain)
        ?.sensisSiteDomain
    }
    return selectedAddons?.find(
      x => x.product === ADD_ON.add_ons['Website'].label,
    )?.sensisSiteDomain
  }
  const resetAutoCoupons = () => {
    setAutoCouponInfo([])
    setAppliedCoupons(false)
  }

  const removeDuplicatesFromArray = (arrayObject, comparisionAttribute) => {
    const uniqueArrayElements = new Set()
    return arrayObject.filter(item => {
      const keyValue = item[comparisionAttribute]
      return uniqueArrayElements.has(keyValue)
        ? false
        : (uniqueArrayElements.add(keyValue), true)
    })
  }

  useEffect(() => {
    if (
      (currentStep < 6 && !isUpgrade) ||
      (isUpgrade &&
        ((currentStep < 2 && is5Plan) || (currentStep < 3 && !is5Plan)))
    )
      return

    async function fetchCoupon({couponCode, planCode, addonsString}) {
      const baseUrl = 'orders/coupon/validate'
      const addonsQueryString = addonsString
        ? `addonsString=${addonsString}&`
        : ''
      const url = `${baseUrl}?${addonsQueryString}couponCode=${couponCode}&country=${selectedCountry.isoValue}&planCode=${planCode}`
      const coupon = await client(url)
      return coupon
    }

    async function applyAutoCoupons() {
      let isFreeAccountWithoutExpiredSubs = true
      const isFreeAccount = !(
        defaultCentersAndApps?.current?.centers?.some(
          center => !FREE_ACCOUNT_CODES.includes(center.code),
        ) ||
        defaultCentersAndApps?.current?.apps?.some(
          app => !FREE_ACCOUNT_CODES.includes(app.code),
        )
      )
      if (isUpgrade) {
        isFreeAccountWithoutExpiredSubs = !(
          expiredCentersAndApps?.current?.centers?.some(
            center => !FREE_ACCOUNT_CODES.includes(center.code),
          ) ||
          expiredCentersAndApps?.current?.apps?.some(
            app => !FREE_ACCOUNT_CODES.includes(app.code),
          )
        )
      }
      console.log(
        'isFreeAccountWithoutExpiredSubs' + isFreeAccountWithoutExpiredSubs,
      )
      const codes = []
      for (const key in selectedCentersAndApps) {
        codes.push(...selectedCentersAndApps[key].map(x => x.code))
      }
      if (!is5Plan) {
        const isMCIncluded = upgradeAccountInfo.addOns.some(({addOnCode}) =>
          MC_PLAN_CODES.includes(addOnCode),
        )
        const isMCInCart = selectedAddons.some(({planCode}) =>
          MC_PLAN_CODES.includes(planCode),
        )
        if (isMCInCart && !isMCIncluded) {
          const mcAddOnCode = selectedAddons.find(
            ({planCode}) =>
              planCode === 'addon-4-mc-m2m' || planCode === 'addon-4-mcpro-m2m',
          )?.planCode
          codes.push(mcAddOnCode)
        }
      }
      let response = await client(
        `cc/order/fetchPlanCoupons/${
          selectedCountry.isoValue
        }/${codes.join()}/${clientType}`,
      )
      let autoCouponData = []
      if (response) {
        if (clientType !== 'keap') {
          //check for keap-nci-mc coupon inthe responseand if present remove it
          response = response.filter(item => item.coupon_code !== 'keap-nci-mc')
        }
        for (let coupon of response) {
          let couponApplicable = false
          if (coupon?.new_client_only_flag === 'Y') {
            //coupon is applicable only to new clients
            if (
              isFreeAccount &&
              isFreeAccountWithoutExpiredSubs &&
              coupon.new_auto_apply === 'Y'
            ) {
              // Add only coupon that is auto applied for new clients
              couponApplicable = true
            }
          } else {
            //coupon is applicable both new and existing clients
            if (
              isFreeAccount &&
              isFreeAccountWithoutExpiredSubs &&
              coupon.new_auto_apply === 'Y'
            ) {
              // Add only coupon that is auto applied for new client
              couponApplicable = true
            } else if (
              !(isFreeAccount && isFreeAccountWithoutExpiredSubs) &&
              coupon.existing_auto_apply === 'Y'
            ) {
              // Add only coupon that is auto applied for existing client
              couponApplicable = true
            } else {
              if (!is5Plan) {
                couponApplicable = true
              }
            }
          }
          if (couponApplicable) {
            if (!is5Plan) {
              const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
              let couponResponse = await client(createUrl)
              autoCouponData.push(couponResponse)
            } else {
              let planPrefix = coupon?.plan_code.slice(0, 2)
              switch (planPrefix) {
                case 'bc':
                  {
                    const isBCInCart = selectedCentersAndApps?.centers.some(
                      ({code}) => BC_PLANS.includes(code),
                    )
                    const isBCInDefault =
                      defaultCentersAndApps?.current?.centers.some(({code}) =>
                        BC_PLANS.includes(code),
                      )
                    const isBCCouponAlreadyApplied = defaultCouponInfo?.filter(
                      coupon => {
                        if (
                          coupon.appliesToPlans.some(
                            item =>
                              item === 'bc_plus-5-thryv-m2m' ||
                              item === 'bc_pro-5-thryv-m2m' ||
                              item === 'bc_unlm-5-thryv-m2m',
                          )
                        )
                          return true
                        else return false
                      },
                    )
                    if (
                      !isBCCouponAlreadyApplied ||
                      (isBCCouponAlreadyApplied &&
                        isBCCouponAlreadyApplied.length === 0)
                    ) {
                      if (isBCInCart) {
                        if (!isBCInDefault) {
                          const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
                          let couponResponse = await client(createUrl)
                          autoCouponData.push(couponResponse)
                        }
                      }
                    }
                  }
                  break
                case 'mc':
                  {
                    const isMCInCart = selectedCentersAndApps?.centers?.some(
                      ({code}) => MC_PLANS.includes(code),
                    )
                    const isMCInDefault =
                      defaultCentersAndApps?.current?.centers?.some(({code}) =>
                        MC_PLANS.includes(code),
                      )
                    const isMCCouponAlreadyApplied = defaultCouponInfo?.filter(
                      coupon => {
                        if (
                          coupon.appliesToPlans.some(
                            item =>
                              item === 'mc_plus-5-thryv-m2m' ||
                              item === 'mc_pro-5-thryv-m2m',
                          )
                        )
                          return true
                        else return false
                      },
                    )
                    if (
                      !isMCCouponAlreadyApplied ||
                      (isMCCouponAlreadyApplied &&
                        isMCCouponAlreadyApplied.length === 0)
                    ) {
                      if (isMCInCart) {
                        if (!isMCInDefault) {
                          const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
                          let couponResponse = await client(createUrl)
                          console.log('couponResponse ::', couponResponse)
                          autoCouponData.push(couponResponse)
                        }
                      }
                    }
                  }
                  break
                case 'rc':
                  {
                    const isRCInCart = selectedCentersAndApps?.centers.some(
                      ({code}) => RC_PLANS.includes(code),
                    )
                    const isRCInDefault =
                      defaultCentersAndApps?.current?.centers.some(({code}) =>
                        RC_PLANS.includes(code),
                      )
                    const isRCCouponAlreadyApplied = defaultCouponInfo?.filter(
                      coupon => {
                        if (
                          coupon.appliesToPlans.some(
                            item => item === 'rc_plus-5-thryv-m2m',
                          )
                        )
                          return true
                        else return false
                      },
                    )
                    if (
                      !isRCCouponAlreadyApplied ||
                      (isRCCouponAlreadyApplied &&
                        isRCCouponAlreadyApplied.length === 0)
                    ) {
                      if (isRCInCart) {
                        if (!isRCInDefault) {
                          const createUrl = `orders/coupon/validate?couponCode=${coupon?.coupon_code}&country=${selectedCountry.isoValue}&planCode=${coupon?.plan_code}`
                          let couponResponse = await client(createUrl)
                          autoCouponData.push(couponResponse)
                        }
                      }
                    }
                  }
                  break
                default:
                  break
              }
            }
          }
        }
      }
      const isWebsiteApp =
        selectedCentersAndApps?.apps.find(
          ({name}) => name === 'Professionally Designed Website',
        ) || selectedAddons?.find(({product}) => product === 'Website')
      const isTrialPlan = upgradeAccountInfo?.planCode?.includes('trial')
      const sensisDomain = findSensisSiteDomain()
      const addonsString =
        (!selectedCentersAndApps
          ? selectedAddons
              ?.filter(addon => addon.planCode !== undefined)
              ?.map(addon => addon.planCode)
          : [
              ...selectedCentersAndApps.centers,
              ...selectedCentersAndApps.apps,
              ...selectedCentersAndApps.oneTimes,
            ]
              .map(obj => obj.code)
              .join(',')) || ''
      let incentive = incentiveCoupon
      let data

      if (!isNewOrder && upgradeAccountInfo.status !== 'active') {
        const winBackCoupon = WIN_BACK_4_COUPONS[selectedPlan]
        if (winBackCoupon)
          data = {couponCode: winBackCoupon, planCode: selectedPlan}
      } else if (isTrialPlan) {
        data = {
          couponCode: 'transition75',
          planCode: selectedPlan,
        }
      }

      let domainData

      if ((isWebsiteApp?.name || isWebsiteApp?.product) && sensisDomain) {
        domainData = {
          couponCode: 'urldbdesignfee',
          planCode: selectedPlan,
        }
      }

      const couponInfo = data && (await fetchCoupon({...data, addonsString}))
      const domainCoupon =
        domainData && (await fetchCoupon({...domainData, addonsString}))

      const cohortCouponApplies =
        isCohort &&
        (selectedCentersAndApps?.oneTimes.find(
          fee => fee.code === 'thryvprfee',
        ) ||
          selectedAddons?.find(addon => addon.planCode === 'onboarding-fee'))

      const cohortCoupon =
        cohortCouponApplies &&
        (await fetchCoupon({
          couponCode: 'cohort-waive-onboardingfee',
          addonsString,
        }))

      if (incentive) setIncentiveCoupon(couponInfo)

      if (couponInfo) autoCouponData.push(couponInfo)
      if (domainCoupon) autoCouponData.push(domainCoupon)
      if (cohortCoupon) autoCouponData.push(cohortCoupon)

      if (autoCouponData.length > 0) setAutoCouponInfo(autoCouponData)
    }
    console.log(
      'ccCouponInfo before calling applyAutoCoupons is  :: ',
      JSON.stringify(ccCouponInfo),
    )
    console.log(applyAutoCoupons)
    applyAutoCoupons()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep, upgradePaymentPreview])

  useEffect(() => {
    console.log('Inside useEffect of setOrderCouponCodes')
    if (
      ((currentStep < 6 && !isUpgrade) ||
        (isUpgrade && ((currentStep < 2 && is5Plan) || !is5Plan))) &&
      appliedCoupons
    )
      return
    console.log(
      'Array filter operation :: ',
      [...autoCouponInfo, ccCouponInfo?.isValid ? ccCouponInfo : null].filter(
        Boolean,
      ),
    )

    console.log('defaultCouponInfo :: ', defaultCouponInfo)

    console.log('Merged array :: ', [
      ...[
        ...autoCouponInfo,
        ccCouponInfo?.isValid ? ccCouponInfo : null,
      ].filter(Boolean),
      ...(defaultCouponInfo ? defaultCouponInfo : []),
    ])
    setOrderCouponCodes(
      removeDuplicatesFromArray(
        [
          ...[
            ...autoCouponInfo,
            ccCouponInfo?.isValid ? ccCouponInfo : null,
          ].filter(Boolean),
          ...(defaultCouponInfo ? defaultCouponInfo : []),
        ],
        'code',
      ),
    )
    setAppliedCoupons(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    autoCouponInfo,
    ccCouponInfo,
    defaultCouponInfo,
    setOrderCouponCodes,
    currentStep,
    appliedCoupons,
  ])
  return {autoCouponInfo, resetAutoCoupons}
}

export default useAutoCoupons
