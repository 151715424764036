import React, {useEffect, useState} from 'react'
import {useAuthClient} from '../../utils/use-auth-client'
import {Button, Checkbox} from '@thryvlabs/maverick'
// import {toast} from 'react-toastify'

export default function PlanIncentiveModal({
  close,
  selectedCountry,
  clientGroup,
  selectedPlan,
  selectedAddon,
  setDefaultIncentiveCoupon,
}) {
  const client = useAuthClient()
  const [coupons, setCoupons] = useState([])

  // Function invoked after clicking the individual coupon checkbox
  const addCoupon = (plan_desc, couponCode, isChecked) => {
    console.log(coupons)
    let existingItems = {...coupons}
    for (let coupon of existingItems[plan_desc]) {
      if (isChecked) {
        // Checking the checkbox
        if (coupon.coupon_code === couponCode) {
          coupon.isChecked = isChecked
        } else {
          coupon.isChecked = false
        }
      } else {
        // Unchecking the checkbox
        if (coupon.coupon_code === couponCode) {
          coupon.isChecked = isChecked
        }
      }
    }
    setCoupons(existingItems)
  }

  // Function invoked after clicking the "Apply" button
  const handleApply = async () => {
    console.log('Coupons after apply button click  :: ', coupons)
    let finalCoupons = []
    for (let key of Object.keys(coupons)) {
      console.log(
        'coupon array of' + key + ' :: ' + JSON.stringify(coupons[key]),
      )
      let selectedCoupon = coupons[key].filter(item => item.isChecked)
      console.log('Selected coupon is  :: ' + JSON.stringify(selectedCoupon))
      if (selectedCoupon && selectedCoupon.length > 0) {
        let couponValidationUrl = `orders/coupon/validate?couponCode=${selectedCoupon[0].coupon_code}&country=${selectedCountry}&planCode=${selectedCoupon[0].plan_code}`
        let couponResponse = await client(couponValidationUrl)
        if (couponResponse.isValid) {
          finalCoupons.push(couponResponse)
        } else {
          console.log('Coupon is In Valid')
        }
      }
    }
    console.log('Final coupons :: ' + JSON.stringify(finalCoupons))
    if (finalCoupons && finalCoupons.length > 0) {
      setDefaultIncentiveCoupon(finalCoupons)
      // toast.success('Keap Partner Incentive Coupon applied!.')
    }
  }

  // useEffect call so that API call is made to fetch the coupons, upon opening the modal page
  useEffect(() => {
    console.log('Inside  useEffect of plan-discounts-modal.js')
    // Function to fetch coupons from API
    async function fetchCoupons(country, clientGroup) {
      // Pass the clientGroup as null when it is not set
      if (!clientGroup) clientGroup = 'null'

      // Fetch only the plans that are newly added, that is MC plus is deffault for Keap partner
      // let planscode = [defaultPlan[0]?.code, ...selectedAddon]
      const codes = []
      codes.push(selectedPlan?.map(plan => plan.code))
      codes.push(selectedAddon?.map(addon => addon.code))
      if (country && codes && codes.length > 0) {
        let response = await client(
          `cc/order/fetchPlanCoupons/${country}/${codes.join(
            ',',
          )}/${clientGroup}`,
        )
        if (response && response.length > 0) {
          let groupedCoupons = {}
          for (let coupon of response) {
            // Check if coupon is applicable, based on the new client only flag
            if (coupon?.new_client_only_flag === 'Y') {
              coupon['couponApplicable'] = false
            } else {
              coupon['couponApplicable'] = true
            }

            // Check if the coupon is auto applicable or not and add isChecked attribute accordingly
            if (coupon.new_auto_apply === 'Y') {
              // New client and coupon is auto applied for new clients
              coupon['isChecked'] = true
            } else {
              coupon['isChecked'] = false
            }
            coupon['isDisabled'] = false
          }
          let couponsTobeDisplayed = response.filter(
            coupon => coupon.couponApplicable,
          )
          // Group the coupons based on plan code
          couponsTobeDisplayed.forEach(coupon => {
            if (!groupedCoupons[coupon.plan_desc]) {
              groupedCoupons[coupon.plan_desc] = []
            }
            groupedCoupons[coupon.plan_desc].push(coupon)
          })
          for (let key of Object.keys(groupedCoupons)) {
            console.log('Key :: ', key)
            console.log('groupedCoupons[key] :: ', groupedCoupons[key])
            console.log(
              'groupedCoupons[key] count :: ',
              groupedCoupons[key].length,
            )
            if (groupedCoupons[key].length === 1) {
              groupedCoupons[key][0].isDisabled = false
            }
          }
          console.log('groupedCoupons :: ', groupedCoupons)
          setCoupons(groupedCoupons)
        } else {
          setCoupons(null)
        }
      } else {
        setCoupons(null)
      }
    }
    fetchCoupons(selectedCountry, clientGroup)
    // eslint-disable-next-line
  }, [])

  console.log('Coupons before rendering  :: ', coupons)
  //console.log('Coupons length ::', Object.keys(coupons))
  return (
    <div>
      <div>
        <h2> Eligible Incentives for Selected Plans</h2>
      </div>
      <br></br>
      {coupons &&
        Object.keys(coupons).map(plan_desc => (
          <div key={plan_desc}>
            <h4>
              {' '}
              <b>{plan_desc}</b>{' '}
            </h4>
            <ul>
              {coupons[plan_desc].map(coupon => (
                <li key={coupon.coupon_code}>
                  <Checkbox
                    hasLabel
                    name={coupon.coupon_code}
                    label={coupon.coupon_desc}
                    isChecked={coupon.isChecked}
                    disabled={coupon.isDisabled}
                    onChange={() =>
                      addCoupon(
                        plan_desc,
                        coupon.coupon_code,
                        !coupon.isChecked,
                      )
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      <Button variant="primary" style={{marginTop: '20px'}} onClick={close}>
        CANCEL
      </Button>
      <Button
        onClick={handleApply}
        variant="primary"
        style={{marginTop: '20px'}}
        disabled={!coupons}
      >
        Apply
      </Button>
    </div>
  )
}
