import React from 'react'
import {MainContainer, TitleContainer} from './ui/new-order-layout'
import {Header, Icon, Button} from '@thryvlabs/maverick'
import {CancelOrderModal} from '../components/cancel-order-modal'
import {UpdateAddressModal} from './update-address-modal'
import {ConfirmGoBackModal} from './confirm-go-back-modal'
import {BillingNoticeModal} from './billing-notice-modal'
import {useLocation} from 'react-router'

import SkeletonLoader from '../components/skeleton-loader'

function NewOrderLayout({
  prevStep,
  nextStep,
  showContinueButton,
  showCancelButton,
  isLoading,
  title,
  disableNextButton,
  children,
  cancelUrl = '/orders',
  showUpdateAddressModal,
  selectedCountry,
  accountInfo,
  displayStyle,
  centeredHeader = true,
  style,
  cancelOrder,
  disableUpdateAddressModal,
  disableOpenAddressModal,
  showBackButton = true,
  showModal,
  kickoffApptId,
  creativeApptId,
  countryISOValue,
  setCreativeConfirmed,
  setOnboardingConfirmed,
  setOnboardingCallDate,
  setCreativeCallDate,
  order5NUId,
  isCancelOrder5,
  cancelOrder5,
  captureOrderNewUpgrade,
  order5OnboardingApptId,
  order5CreativeApptId,
  setOrder5CreativeApptId,
  setOrder5OnboardingApptId,
  isBillingNoticeDisplayed,
  setIsBillingNoticeDisplayed,
}) {
  console.log('title from new-order-layout is 1 :: ', title)
  console.log(
    'isBillingNoticeDisplayed from new-order-layout is :: ',
    isBillingNoticeDisplayed,
  )
  const displayBillingNoticeModal =
    title === 'Entitlements & Add-Ons' ? true : false
  const {pathname} = useLocation()
  const isUpgrade = pathname === '/order/upgrade'
  console.log('isUpgrade from new-order-layout is ', isUpgrade)
  if (isLoading) {
    return (
      <div className="d-flex justify-content-center">
        <SkeletonLoader height="50vh" width="85vw" />
      </div>
    )
  }
  return (
    <MainContainer
      style={{
        display: displayStyle || 'grid',
        ...style,
      }}
    >
      <TitleContainer title={title} centeredHeader={centeredHeader}>
        {!cancelOrder && showBackButton && (
          <div
            className={`${centeredHeader && 'ml-4 mt-2'}`}
            style={{position: centeredHeader ? 'absolute' : 'static', left: 0}}
          >
            {showModal ? (
              <ConfirmGoBackModal
                altButton={
                  <Icon
                    type="solid"
                    variant="arrowLeft"
                    className="back__arrow mb-2"
                  />
                }
                prevStep={prevStep}
                kickoffApptId={kickoffApptId}
                creativeApptId={creativeApptId}
                countryISOValue={countryISOValue}
                setCreativeConfirmed={setCreativeConfirmed}
                setOnboardingConfirmed={setOnboardingConfirmed}
                setOnboardingCallDate={setOnboardingCallDate}
                setCreativeCallDate={setCreativeCallDate}
                order5NUId={order5NUId}
                captureOrderNewUpgrade={captureOrderNewUpgrade}
                order5OnboardingApptId={order5OnboardingApptId}
                order5CreativeApptId={order5CreativeApptId}
                setOrder5CreativeApptId={setOrder5CreativeApptId}
                setOrder5OnboardingApptId={setOrder5OnboardingApptId}
              />
            ) : (
              <Icon
                type="solid"
                variant="arrowLeft"
                className="back__arrow mb-2"
                onClick={prevStep}
              />
            )}
          </div>
        )}
        {title && (
          <Header fontWeight="normal" variant="h1">
            {title}
          </Header>
        )}
      </TitleContainer>
      {children}
      {(showContinueButton || showCancelButton) && (
        <div className="button__container mt-5" style={{columnGap: '10px'}}>
          {showCancelButton && (
            <CancelOrderModal
              cancelUrl={cancelUrl}
              order5NUId={order5NUId}
              isCancelOrder5={isCancelOrder5}
              cancelOrder5={cancelOrder5}
            />
          )}
          {cancelOrder && (
            <CancelOrderModal
              cancelUrl="/my-accounts"
              order5NUId={order5NUId}
              isCancelOrder5={isCancelOrder5}
              cancelOrder5={cancelOrder5}
            />
          )}
          {showContinueButton &&
            isUpgrade &&
            !isBillingNoticeDisplayed &&
            displayBillingNoticeModal && (
              <BillingNoticeModal
                setIsBillingNoticeDisplayed={setIsBillingNoticeDisplayed}
              />
            )}
          {showContinueButton && (!isUpgrade || isBillingNoticeDisplayed) && (
            <Button
              data-testid={'continue-button'}
              variant="primary"
              type="button"
              onClick={nextStep}
              disabled={disableNextButton}
            >
              Continue
            </Button>
          )}
          {showUpdateAddressModal && (
            <UpdateAddressModal
              nextStep={nextStep}
              selectedCountry={selectedCountry}
              accountInfo={accountInfo}
              disableUpdateAddressModal={disableUpdateAddressModal}
              disableOpenAddressModal={disableOpenAddressModal}
            />
          )}
        </div>
      )}
    </MainContainer>
  )
}

export {NewOrderLayout}
