import * as image from '../../assets/index'

export const PROSPECTING_TOOLS_AUTHORIZED_ROLES = {
  packageRecommendation: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'yello',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  thryvDemoRequest: ['admin', 'premise'],
  thryvPaySavingsProposal: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'yello',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
  thryvLeads: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'reseller',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  thryvCSN: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
  ],
  seoMBGProposal: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
  seoProposal: [
    'admin',
    'clientcare',
    'leadconverter',
    'inside',
    'premise',
    'thryvonly',
    'tss',
    'premisedemo',
    'insidedemo',
    'premisepilot',
    'vivialdemo',
    'australia',
  ],
}

/**
 * @typedef {{
 *   title: string;
 *   image: string;
 *   type: 'link' | 'modal';
 *   url: string;
 *   requiredRoles?: string[];
 *   hidden?: boolean;
 * }} ToolData
 */

/**
 * @type {Array<ToolData>}
 */
export const EDUCATIONAL_TOOLS = [
  {
    type: 'modal',
    title: 'Product Leave Behinds',
    url: 'product-leave-behinds',
    image: image.leaveBehindsIcon,
  },
  {
    type: 'modal',
    title: 'Other Leave Behinds',
    url: 'other-leave-behinds',
    image: image.thryvjobaids,
  },
  {
    type: 'modal',
    title: 'Local Ads',
    url: 'local-ads',
    image: image.localAdsIcon,
  },
  {
    type: 'modal',
    title: 'Client Testimonials',
    url: 'testimonials',
    image: image.testimonial,
  },
  {
    type: 'link',
    title: 'Learning Hub',
    url: 'https://thryv.sharepoint.com/teams/TheLearningHub/SitePages/CollabHome.aspx',
    image: image.learningHubLogo,
  },
  {
    type: 'modal',
    title: 'Thryv Marketing Center Demos',
    url: 'marketing-center-demos',
    image: image.marketingCenterDemoLogo,
    requiredRoles: ['admin', 'leadconverter', 'premisepilot'],
  },
  {
    type: 'modal',
    title: 'Thryv Business Center Demos',
    url: 'business-center-demos',
    image: image.businessCenterDemoLogo,
  },
  {
    type: 'modal',
    title: 'Thryv Command Center Demos',
    url: 'command-center-demos',
    image: image.commandCenterDemosIcon,
  },
  {
    type: 'link',
    title: 'Thryv Support Center',
    url: 'https://learn.thryv.com/hc/en-us',
    image: image.learnLogo,
  },
  {
    type: 'modal',
    title: 'Thryv Reporting Center Demos',
    url: 'reporting-center-demos',
    image: image.thryvReportingCenterDemosIcon,
  },
  {
    type: 'modal',
    title: 'ThryvPay Demos',
    url: 'thryvpay-demos',
    image: image.thryvPayDemoLogo,
  },
  {
    type: 'modal',
    title: 'Thryv Marketplace Demos',
    url: 'marketplace-demos',
    image: image.marketplaceDemosIcon,
  },
  {
    type: 'link',
    title: 'Thryv Specialties',
    url: 'http://industries.thryv.info/',
    image: image.specialitiesLogo,
    hidden: true,
    requiredRoles: [
      'admin',
      'clientcare',
      'leadconverter',
      'inside',
      'premise',
      'reseller',
      'thryvonly',
      'tss',
      'yello',
      'premisedemo',
      'insidedemo',
      'premisepilot',
      'vivialdemo',
    ],
  },
]
