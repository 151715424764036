import React, {useState} from 'react'
import {Header, Button, Icon, ButtonGroup} from '@thryvlabs/maverick'
import {useAuthClient} from '../../utils/use-auth-client'
import {toast} from 'react-toastify'
import AsyncSelect from 'react-select/async'

export default function CouponModal({
  close,
  selectedCountry,
  clientGroup,
  selectedPlan,
  selectedAddon,
  kpSelectedCoupon,
  setkpSelectedCoupon,
}) {
  const client = useAuthClient()
  const [coupon, setCoupon] = useState(kpSelectedCoupon?.code)
  const [selectedCoupon, setSelectedCoupon] = useState(null)

  const fetchNameOptions = async inputValue => {
    const response = await client(
      `cc/order/fetchCoupons/${selectedCountry}/${inputValue}`,
    )
    return response.map(item => ({
      label: item.coupon_code,
      value: item.coupon_code,
    }))
  }

  const loadOptions = (inputValue, callback) => {
    if (inputValue && inputValue.length >= 3) {
      fetchNameOptions(inputValue).then(options => {
        callback(options)
      })
    } else if (!inputValue) {
      callback([])
    }
  }

  const handleChange = e => {
    setSelectedCoupon({
      label: e.value,
      value: e.value,
    })
    setCoupon(e.value)
  }

  const resetCoupon = async () => {
    setSelectedCoupon({label: '', name: ''})
    setCoupon(null)
  }

  const validateCoupon = async () => {
    if (coupon && kpSelectedCoupon?.code !== coupon) {
      setCoupon(coupon.trim())
      try {
        const codes = []
        codes.push(selectedPlan?.map(plan => plan.code))
        codes.push(selectedAddon?.map(addon => addon.code))

        let url = `orders/coupon/validate?couponCode=${coupon}&country=${selectedCountry}&addonsString=${codes.join()}`
        const response = await client(url)
        if (response.isValid) {
          // toast.success('Coupon applied.')
          setkpSelectedCoupon([{...response, code: coupon.trim()}])
        }
        if (!response.isValid) {
          toast.error('Invalid coupon.')
        }
      } catch (error) {
        // setCode('')
        toast.error('Error in validating coupon.')
        if (error.status === 404) {
          //   setCcCouponInfo({isValid: false})
        }
      }
    }
  }

  const handleSubmit = async () => {
    await validateCoupon()
  }

  return (
    <div
      data-testid="coupon-modal"
      className="d-flex flex-column justify-content-between"
      style={{width: '500px', height: '70vh', overflow: 'auto'}}
    >
      <div className="w-100">
        <div className="w-100 mb-3 d-flex align-items-center justify-content-between">
          <Header variant="h2" fontWeight="semibold">
            Coupons
          </Header>
          <Icon
            style={{cursor: 'pointer'}}
            onClick={close}
            type="regular"
            variant="x"
            height="18"
            width="18"
            color="#A3A5A7"
          />
        </div>
        <AsyncSelect
          cacheOptions
          placeholder="Enter a Coupon"
          loadOptions={loadOptions}
          onChange={handleChange}
          value={selectedCoupon}
          theme={theme => ({
            ...theme,
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary25: 'silver',
              primary: 'black',
            },
          })}
        />
        {selectedCoupon &&
        selectedCoupon.label &&
        selectedCoupon.label.length ? (
          <>
            <div
              className="mt-3 mb-3 d-flex justify-content-start align-items-end flex-wrap"
              onClick={resetCoupon}
            >
              <Icon
                className="reset-button mr-2"
                type="regular"
                variant="circleX"
                color="#FF5000"
                style={{
                  width: '18px',
                  height: '18px',
                }}
              />
              <div className="mr-2">{selectedCoupon.label}</div>
              {/* <div className="mr-2">({selectedCoupon.})</div> */}
            </div>
          </>
        ) : null}
      </div>
      <ButtonGroup
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
          columnGap: '16px',
        }}
      >
        <Button variant="text" level={1} textVariant="light" onClick={close}>
          CANCEL
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Apply
        </Button>
      </ButtonGroup>
    </div>
  )
}
