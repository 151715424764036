import React from 'react'
import {
  KeapPartnerArticle,
  KeapPartnerHeader,
  KeapPartnerHomeWrapper,
  KeapPartnerImage,
  KeapPartnerImageLink,
  KeapPartnerImagesContainer,
  KeapPartnerLogo,
  KeapPartnerMainContentContainer,
  KeapPartnerOrangeLink,
  KeapPartnerSection,
  KeapPartnerSectionParagraph,
  KeapPartnerSectionTitle,
  KeapPartnerSidebarContainer,
  // KeapPartnerSidebarDivider,
  KeapPartnerSidebarFooter,
  KeapPartnerSidebarKeapLink,
  KeapPartnerSidebarLink,
  // KeapPartnerSidebarMutedText,
  KeapPartnerSidebarNav,
  KeapPartnerSidebarSubtitle,
  KeapPartnerSidebarThryvLink,
  KeapPartnerTitle,
  ThryvColorSpan,
  ThryvLogoForResourceLink,
} from './styled.components'
import {useAuth0} from '@auth0/auth0-react'
import {toast} from 'react-toastify'
import {
  keapPartnerLogo,
  mc5StarsReview,
  thryvLogoForKeapResouces,
  thryvMCPdfImg,
  ThryvMCVideoImg,
} from '../../../assets'
import {marketingCenterPDF} from '../../../assets/documents/tools/tools-docs'
import {GenerateBuyNowLink} from '../../../components/generate-buy-now-link'
import {useAuthClient} from '../../../utils/use-auth-client'
import {KeapMCIcons} from './mc-icons'
import {KeapMCAttributes} from './mc-attributes'

function HomeKeapPartner() {
  const {user} = useAuth0()
  const [userData, setUserData] = React.useState(null)
  const client = useAuthClient()

  const wellcomeName = React.useMemo(() => {
    if (!userData?.user_metadata?.firstname) return '...'
    return `${userData?.user_metadata?.firstname}`
  }, [userData?.user_metadata])

  const fetchUserData = async () => {
    try {
      const url = 'user?email=' + user?.email
      const data = await client(url)
      if (data) {
        setUserData(data)
      }
    } catch (e) {
      toast.error('Failed to fetch profile data')
    }
  }

  React.useEffect(() => {
    fetchUserData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    function handleScroll() {
      const sidebarFooter = document.querySelector('#sidebar-footer')
      console.log(sidebarFooter.classList.contains('show'))
      if (window.scrollY >= 52) {
        sidebarFooter.style.opacity = 1
      } else {
        sidebarFooter.style.opacity = 0
      }
    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <KeapPartnerHomeWrapper>
      <KeapPartnerMainContentContainer>
        <KeapPartnerHeader>
          <KeapPartnerTitle>Welcome {wellcomeName}!</KeapPartnerTitle>
          <div>
            <GenerateBuyNowLink userData={userData} />
          </div>
        </KeapPartnerHeader>
        <KeapPartnerSection>
          <KeapPartnerImageLink
            href={marketingCenterPDF}
            target="_blank"
            rel="noopener noreferrer"
          >
            <KeapPartnerImage src={thryvMCPdfImg} alt="Marketing Center PDF" />
          </KeapPartnerImageLink>
          <KeapPartnerArticle>
            <KeapPartnerSectionTitle
              style={{
                fontSize: '3.25rem',
              }}
            >
              Help your clients kick growth into{' '}
              <ThryvColorSpan>high gear.</ThryvColorSpan>
            </KeapPartnerSectionTitle>
            <KeapPartnerSectionParagraph>
              Small business marketing software that it easy for your clients to
              run ads, boost their online presence, understand their customers
              and make data-driven decisions.
            </KeapPartnerSectionParagraph>

            <img
              width={400}
              alt="Marketing Center Reviews"
              src={mc5StarsReview}
            />
          </KeapPartnerArticle>

          <KeapPartnerArticle>
            <KeapPartnerSectionTitle>
              What is{' '}
              <KeapPartnerOrangeLink
                href="https://www.thryv.com/marketing-center/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Thryv Marketing Center
              </KeapPartnerOrangeLink>
              ?
            </KeapPartnerSectionTitle>

            <KeapPartnerImagesContainer>
              <KeapMCAttributes />
              <KeapPartnerImageLink
                href="https://www.thryv.com/marketing-center/?wvideo=27uu6alyer"
                target="_blank"
                rel="noopener noreferrer"
              >
                <KeapPartnerImage
                  src={ThryvMCVideoImg}
                  alt="Marketing Center Video"
                />
              </KeapPartnerImageLink>
            </KeapPartnerImagesContainer>
            <KeapMCIcons />
          </KeapPartnerArticle>
        </KeapPartnerSection>
      </KeapPartnerMainContentContainer>

      <KeapPartnerSidebarContainer>
        {/* <KeapPartnerSidebarSubtitle>Support</KeapPartnerSidebarSubtitle>
        <KeapPartnerSidebarMutedText>
          Toll Free{' '}
          <KeapPartnerSidebarMutedLink href="tel:18449984798">
            (844) 998-4798
          </KeapPartnerSidebarMutedLink>
        </KeapPartnerSidebarMutedText>

        <KeapPartnerSidebarDivider /> */}

        <KeapPartnerSidebarSubtitle>Resources</KeapPartnerSidebarSubtitle>
        <KeapPartnerSidebarNav>
          <KeapPartnerSidebarThryvLink
            href={'https://www.thryv.com/thryv-partner-program-agreement/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ThryvLogoForResourceLink
              style={{
                backgroundImage: `url(${thryvLogoForKeapResouces})`,
              }}
            />{' '}
            Partner Program Terms
          </KeapPartnerSidebarThryvLink>
          <KeapPartnerSidebarThryvLink
            href={'/marketing-center.pdf'}
            target="_blank"
            rel="noopener noreferrer"
          >
            Marketing Center PDF
          </KeapPartnerSidebarThryvLink>
          <KeapPartnerSidebarKeapLink
            href={'https://infusionsoft.my.site.com/Partners/login?locale=us'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <KeapPartnerLogo
              style={{
                backgroundImage: `url(${keapPartnerLogo})`,
              }}
            />{' '}
            Partner Portal
          </KeapPartnerSidebarKeapLink>
          <KeapPartnerSidebarKeapLink
            href={
              'https://nam02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fdrive.google.com%2Ffile%2Fd%2F1rdQ8hEBHyB6T5dHTYLzpOJFz1f0oHH8o%2Fview&data=05%7C02%7Claurinda.gorman%40thryv.com%7C95712e1cfee040e49bb108dd627412ce%7Cdbd3a8f5232741939559a36e9ccb40f9%7C0%7C0%7C638774972873022165%7CUnknown%7CTWFpbGZsb3d8eyJFbXB0eU1hcGkiOnRydWUsIlYiOiIwLjAuMDAwMCIsIlAiOiJXaW4zMiIsIkFOIjoiTWFpbCIsIldUIjoyfQ%3D%3D%7C0%7C%7C%7C&sdata=AxuDrhvZMU1UR5shC1%2FwgrYD7ycW1KmmWOYPe8VJlRQ%3D&reserved=0'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <KeapPartnerLogo
              style={{
                backgroundImage: `url(${keapPartnerLogo})`,
              }}
            />{' '}
            Partner Success Program Guide
          </KeapPartnerSidebarKeapLink>
          <KeapPartnerSidebarLink
            href={
              'https://sites.google.com/keap.com/brand-guide-for-keap-partners/home'
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            Partner Brand Guide
          </KeapPartnerSidebarLink>
        </KeapPartnerSidebarNav>

        <KeapPartnerSidebarFooter id="sidebar-footer">
          <GenerateBuyNowLink userData={userData} />
        </KeapPartnerSidebarFooter>
      </KeapPartnerSidebarContainer>
    </KeapPartnerHomeWrapper>
  )
}

export {HomeKeapPartner}
