import React, {useState} from 'react'
import {
  Modal,
  ModalTitle,
  Input,
  Radio,
  withModal,
  Checkbox,
} from '@thryvlabs/maverick'
import {formatPrice} from '../../utils/currency-format'

import {
  marketingCenterIcon,
  ccCouponIcon,
  growthPackageIcon,
  ccDiscountIcon,
} from '../../assets'
import {toast} from 'react-toastify'

import {useGenerateBuyLinkContext} from './generate-buy-now-link-provider'
import {PlanOptionContainer} from '../ui/command-center-layout'
import {CopyLinkContainer} from './styled'
import PlanIncentiveModal from './plan-incentive-modal'
import CouponModal from './coupon-modal'
import {
  DeletableCouponChip,
  XButton,
  KeapPartnerPrimaryButton,
  KeapPartnerOutlinedButton,
  GenerateLinkModalFooter,
} from './styled.components'

function ShareLinkcheckboxRadioButtons() {
  const [selected, setSelected] = useState('MultipleCustomers')

  const handleChange = value => {
    setSelected(value === selected ? value : null) // Toggle selection or unselect
  }

  return (
    <>
      <p className="mb-2" style={{fontWeight: '500'}}>
        Share Link With
      </p>
      <div className="d-flex justify-content-start align-items-center">
        <div className="mr-3">
          <Checkbox
            hasLabel
            name={'SingleCustomer'}
            label={'Single Customer'}
            isChecked={selected === 'SingleCustomer'}
            disabled={true}
            onChange={() => handleChange('SingleCustomer')}
          />
        </div>
        <div className="mr-3">
          <Checkbox
            hasLabel
            name={'MultipleCustomers'}
            label={'Multiple Customers'}
            isChecked={selected === 'MultipleCustomers'}
            // isChecked={true}
            onChange={() => handleChange('MultipleCustomers')}
          />
        </div>
      </div>
    </>
  )
}

function CouponButton({showModal}) {
  return (
    <div
      data-testid="coupon-button"
      onClick={showModal}
      style={{
        border: '1px solid #057AFF',
        borderRadius: '4px',
        padding: '10px 8px',
        cursor: 'pointer',
        backgroundColor: '#057AFF1A',
      }}
    >
      <img
        height="10.5px"
        width="15.88px"
        alt="coupon icon"
        src={ccCouponIcon}
      />
    </div>
  )
}

function PlanIncentiveButton({showModal}) {
  return (
    <div
      data-testid="discount-button"
      onClick={showModal}
      style={{
        border: '1px solid var(--utility-alert-warning, #FAC02B)',
        background:
          'linear-gradient(0deg, rgba(250, 192, 43, 0.10) 0%, rgba(250, 192, 43, 0.10) 100%), #FFF',
        borderRadius: '4px',
        padding: '8px 8px',
        cursor: 'pointer',
        marginLeft: '10px',
      }}
    >
      <img
        height="10.5px"
        width="15.88px"
        alt="coupon icon"
        src={ccDiscountIcon}
      />
    </div>
  )
}

export function GenerateBuyNowLinkModal() {
  const {
    selectedPlan,
    selectedAddon,
    setSelectedAddon,
    kpSelectedCoupon,
    setkpSelectedCoupon,
    isGenerateLinkModalOpen,
    closeGenerateLinkModal,
    handleSubmit,
    initModal,
    onboardingFeeCoupon,
    defaultIncentiveCoupon,
    setDefaultIncentiveCoupon,
    selectedCountry,
    clientGroup,
    keapPartnerId,
    plans,
    generatedUrl,
  } = useGenerateBuyLinkContext()

  const CouponButtonWithModal = withModal(CouponButton, CouponModal)
  const PlanIncentiveButtonWithModal = withModal(
    PlanIncentiveButton,
    PlanIncentiveModal,
  )

  const [copyClicked, setCopyClicked] = useState(false)

  const handleCopyToClipBoard = () => {
    navigator.clipboard.writeText(generatedUrl)
    setCopyClicked(true)
    setTimeout(() => {
      setCopyClicked(false)
    }, 4000)
    toast.success('Link copied.')
  }

  if (!isGenerateLinkModalOpen) return null

  if (!selectedPlan) return null

  const obCouponsStyle = {
    padding: '2px 10px',
    background: '#12fe1d1a',
    color: '#027632',
    fontSize: '12px',
    margin: '0 10px 5px 0',
  }

  const incentiveCouponsStyle = {
    padding: '2px 10px',
    background: '#eaab081c',
    color: '#9a7107',
    fontSize: '12px',
    margin: '0 10px 5px 0',
  }

  return (
    <Modal
      onClose={closeGenerateLinkModal}
      onOpen={initModal}
      // onOpen={!generatedUrl && fetchDefaultCoupons}
      variant="default"
      width="50%"
      openOnLoad
      disableBackdropClick
    >
      <ModalTitle variant="subtitle" size="lg" className="fw-bold mb-14">
        Thryv Marketing Center Buy Now Link Generator
      </ModalTitle>
      <div className="mb-3">
        <p className="mb-2" style={{fontWeight: '500'}}>
          Partner Account Record ID
        </p>
        <Input disabled variant="input-isolated" value={keapPartnerId}></Input>
      </div>
      {generatedUrl ? (
        <CopyLinkContainer>
          <p className="mb-1">Generated URL</p>
          <div className="d-flex justify-content-start align-items-center url-wrapper">
            <div className="g-url">{generatedUrl}</div>
            <button
              onClick={handleCopyToClipBoard}
              className="btn-gurl"
              style={{
                backgroundColor: copyClicked ? '#056e3021' : '#eaeaea', // Change background color
                color: copyClicked ? '#056e30' : 'black', // Change text color
                border: copyClicked ? '1px solid #00b34b' : 'none',
                borderRadius: copyClicked ? '0 4px 4px 0' : 'none',
                fontWeight: copyClicked ? 'bold' : 'normal',
                transition: 'background-color 0.4s, color 0.4s', // Smooth transition for color change
                // transform: animate ? 'scale(1.1)' : 'scale(1)', // Animation: Scale the button when clicked
                // transition: 'transform 0.3s ease-in-out', // Button scaling animation
              }}
            >
              {copyClicked ? 'Copied!' : 'Copy'}
            </button>
          </div>
        </CopyLinkContainer>
      ) : (
        <>
          <div className="mb-3">
            <p className="mb-2" style={{fontWeight: '500'}}>
              {selectedPlan[0]?.name}
            </p>
            <PlanOptionContainer selected>
              <div className="d-flex align-items-center">
                <Radio labelClassName="m-0" toggled={true} />
                <img
                  src={marketingCenterIcon}
                  alt="plan logo"
                  height={32}
                  width={32}
                  className="logo"
                />
                <div>
                  <p className="m-0 dark-text">{selectedPlan[0]?.name}</p>
                </div>
              </div>
              <p className="m-0 dark-text">
                {formatPrice(
                  selectedPlan[0]?.currencies.find(
                    ({currency}) => 'USD' === currency,
                  ).unitAmount,
                )}
              </p>
            </PlanOptionContainer>
          </div>

          <div className="mb-3">
            <p className="mb-2" style={{fontWeight: '500'}}>
              *Growth Package
            </p>
            <div
              className="d-flex justify-content-start align-items-center flex-column"
              style={{
                padding: '10px',
                border: '1px solid #e4e4e4',
                borderRadius: '4px',
              }}
            >
              {/* {plans.apps.map(({code, name, currencies}) => ( */}
              {plans?.apps?.map(addon => (
                <PlanOptionContainer
                  onClick={() => {
                    setSelectedAddon(currentSelection => {
                      const alreadyIn = currentSelection.find(
                        ({code}) => code === addon.code,
                      )
                      if (alreadyIn) {
                        return []
                      }
                      return [addon]
                    })
                  }}
                  key={addon.code}
                  selected={selectedAddon[0]?.code === addon?.code}
                >
                  <div className="d-flex align-items-center">
                    <Radio
                      toggled={selectedAddon[0]?.code === addon?.code}
                      labelClassName="m-0"
                    />
                    <img
                      src={growthPackageIcon}
                      alt="plan logo"
                      height={32}
                      width={32}
                      className="logo"
                    />
                    <div>
                      <p className="m-0 dark-text">{addon?.name}</p>
                    </div>
                  </div>
                  <p className="m-0 dark-text">
                    {formatPrice(
                      addon?.currencies?.find(
                        ({currency}) => currency === 'USD',
                      ).unitAmount,
                    )}
                  </p>
                </PlanOptionContainer>
              ))}
            </div>
          </div>

          <div
            aria-hidden="true"
            style={{display: 'none'}}
            className="mb-2 items-start justify-start flex-column"
          >
            <ShareLinkcheckboxRadioButtons />
          </div>

          <div className="mb-2">
            <p className="mb-2" style={{fontWeight: '500'}}>
              Applied Coupons
            </p>
            <div className="d-flex items-center justify-start flex-wrap">
              {onboardingFeeCoupon &&
                onboardingFeeCoupon.length > 0 &&
                onboardingFeeCoupon.map((coupon, index) => (
                  <span key={coupon.code} style={obCouponsStyle}>
                    {coupon.name}
                  </span>
                ))}
              {defaultIncentiveCoupon &&
                defaultIncentiveCoupon.length > 0 &&
                defaultIncentiveCoupon.map((coupon, index) => (
                  <span key={coupon.code} style={incentiveCouponsStyle}>
                    {coupon.name}
                  </span>
                ))}

              {kpSelectedCoupon &&
                kpSelectedCoupon.length > 0 &&
                kpSelectedCoupon.map(coupon => (
                  <DeletableCouponChip key={coupon.code}>
                    {coupon.name}

                    <XButton
                      onClick={() => {
                        setkpSelectedCoupon(currentSelection => {
                          return currentSelection.filter(
                            ({code}) => code !== coupon.code,
                          )
                        })
                      }}
                    >
                      x
                    </XButton>
                  </DeletableCouponChip>
                ))}
            </div>
          </div>

          <div className="flex items-center justify-end">
            <CouponButtonWithModal
              modalProps={{
                selectedCountry,
                clientGroup,
                selectedPlan,
                selectedAddon,
                kpSelectedCoupon,
                setkpSelectedCoupon,
              }}
            />
            <PlanIncentiveButtonWithModal
              modalProps={{
                selectedCountry,
                clientGroup,
                selectedPlan,
                selectedAddon,
                setDefaultIncentiveCoupon,
              }}
            />
          </div>
          <GenerateLinkModalFooter
            onClick={() => {
              setkpSelectedCoupon([])
              setSelectedAddon([])
            }}
          >
            <KeapPartnerOutlinedButton>Start Over</KeapPartnerOutlinedButton>
            <KeapPartnerPrimaryButton onClick={handleSubmit} variant="primary">
              Generate Link
            </KeapPartnerPrimaryButton>
          </GenerateLinkModalFooter>
        </>
      )}
    </Modal>
  )
}
