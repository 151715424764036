import React from 'react'
import {withModal} from '@thryvlabs/maverick'
import ToolItem from './tool-item'
import ThryvLeadsModal from './thryv-leads-modal'
import * as image from '../../assets/index'
import {ItemsContainer, ItemsContainerTitle} from '../ui/tools-page'
import {useNavigate} from 'react-router-dom'
import {getAuthByRole} from '../../utils/get-screen-auth-by-role'
import {useUserRoles} from '../../utils/use-user-roles'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {
  EDUCATIONAL_TOOLS,
  PROSPECTING_TOOLS_AUTHORIZED_ROLES,
} from './tools-consts'
import {ToolItemModal} from './tool-item-modal'

const ToolsContainer = props => {
  const [toolDisplay, setToolDisplay] = React.useState({
    shown: false,
    title: '',
    url: '',
  })

  const {repId, repIdValue, create, tool, savetool, tRef} = props

  const {roles} = useUserRoles()
  const navigate = useNavigate()
  const {frontendSideNav, frontendShowScans} = useFlags()

  const ToolItemThryvLeads = ({showModal}) => (
    <ToolItem
      description="Thryv Leads"
      imageAlternative={image.thryvLeadsLogo}
      imageAlternativeName="Thryv Leads Logo"
      radioref={tRef}
      redirectUrl=""
      onClick={showModal}
    />
  )

  const ToolItemSalesPresentations = () => (
    <ToolItem
      description="Sales Presentations"
      imageAlternative={image.salesPresentationIcon}
      imageAlternativeName="Sales Presentations Logo"
      redirectUrl=""
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Sales Presentations',
          url: 'sales-presentations',
        })
      }
    />
  )

  const ToolItemSalesBattleCards = () => (
    <ToolItem
      description="Sales Battle Cards"
      imageAlternative={image.salesBattleCardIcon}
      imageAlternativeName="Sales Battle Card Logo"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Sales Battle Cards',
          url: 'sales-battle-cards',
        })
      }
    />
  )

  const ToolItemThryvDemoRequest = () => (
    <ToolItem
      description="Thryv Demo Request"
      imageAlternative={image.thryvDemoRequestIcon}
      imageAlternativeName="Thryv Demo Request Logo"
      radioref={tRef}
      onClick={() => navigate('/new-demo-request')}
    />
  )

  const ToolItemPromotionalOffers = () => (
    <ToolItem
      description="Thryv Promotional Offers"
      imageAlternative={image.ooePromotionalOffersIcon}
      imageAlternativeName="Thryv Promotional Offers"
      onClick={() =>
        setToolDisplay({
          shown: !toolDisplay.shown,
          title: 'Thryv Promotional Offers',
          url: 'promotional-offers',
        })
      }
    />
  )

  const ToolItemThryvLeadsWithModal = withModal(
    ToolItemThryvLeads,
    ThryvLeadsModal,
  )

  return (
    <div className="d-flex justify-content-center align-content-center">
      <div className={`${frontendSideNav && 'col-md-9 col-lg-8 mx-auto'}`}>
        <ToolItemModal
          open={toolDisplay.shown}
          title={toolDisplay.title}
          url={toolDisplay.url}
          onClose={() => {
            setToolDisplay({
              url: '',
              title: '',
              shown: '',
            })
          }}
        />
        <ItemsContainerTitle
          fontWeight="medium"
          className="text-center"
          variant="h2"
          text="Prospecting Tools"
        >
          Prospecting Tools
        </ItemsContainerTitle>
        <ItemsContainer>
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.packageRecommendation,
            roles,
          ) && (
            <ToolItem
              description="Package Recommendation"
              imageAlternative={image.packageRecommendation}
              imageAlternativeName="Package Recommendation"
              repId={repId}
              val={repIdValue || ''}
              create={create}
              tool={tool}
              radioref={savetool}
              onClick={() => navigate('/package-recommendation')}
            />
          )}
          {frontendShowScans && (
            <ToolItem
              description="Thryv Business Analysis"
              imageAlternative={image.allInOneLogo}
              imageAlternativeName="All In One Logo"
              radioref={tRef}
              onClick={() => navigate('/scan-form')}
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvDemoRequest,
            roles,
          ) && <ToolItemThryvDemoRequest />}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvLeads,
            roles,
          ) && <ToolItemThryvLeadsWithModal />}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.seoProposal,
            roles,
          ) && (
            <ToolItem
              description="US/AU/NZ SEO Proposal"
              imageAlternative={image.seoProposalMBG}
              imageAlternativeName="SEO Proposal Icon"
              radioref={tRef}
              redirectUrl="https://seodashboard.thryv.com/okta/"
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.seoProposal,
            roles,
          ) && (
            <ToolItem
              description="CA SEO Proposal"
              imageAlternative={image.seoProposalMBG}
              imageAlternativeName="SEO Proposal Icon"
              radioref={tRef}
              redirectUrl="https://seoproposaltool.thryv.com/us/login"
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvPaySavingsProposal,
            roles,
          ) && (
            <ToolItem
              description="ThryvPay Savings Proposal"
              imageAlternative={image.thryvPaySavingsProposal}
              imageAlternativeName="ThryvPay Savings Proposal"
              radioref={tRef}
              redirectUrl={process.env.REACT_APP_THRYV_PAY_SAVINGS_PROPOSAL_URL}
            />
          )}
          {getAuthByRole(
            PROSPECTING_TOOLS_AUTHORIZED_ROLES.thryvCSN,
            roles,
          ) && (
            <ToolItem
              description="Thryv CSN"
              imageAlternative={image.ooeSearchIcon}
              imageAlternativeName="ThryvPay Savings Proposal"
              radioref={tRef}
              redirectUrl="https://leads.thryv.com/"
            />
          )}
          <ToolItemPromotionalOffers />
          <ToolItemSalesPresentations />
          <ToolItemSalesBattleCards />
        </ItemsContainer>
        <br />
        <ItemsContainerTitle
          className="text-center"
          fontWeight="medium"
          variant="h2"
          text="Educational Tools"
        >
          Educational Tools
        </ItemsContainerTitle>
        <br />
        <ItemsContainer>
          {EDUCATIONAL_TOOLS.map(tool => {
            if (tool.hidden) return null
            if (
              tool.requiredRoles &&
              !getAuthByRole(tool.requiredRoles, roles)
            ) {
              return null
            }
            return (
              <ToolItem
                key={tool.url}
                description={tool.title}
                imageAlternative={tool.image}
                imageAlternativeName={`${tool.description} Logo`}
                {...(tool.type === 'link'
                  ? {
                      redirectUrl: tool.url,
                    }
                  : {
                      onClick: () => {
                        setToolDisplay({
                          shown: !toolDisplay.shown,
                          title: tool.title,
                          url: tool.url,
                        })
                      },
                    })}
              />
            )
          })}
        </ItemsContainer>
      </div>
    </div>
  )
}

export default ToolsContainer
