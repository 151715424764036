import React from 'react'
import {ParagraphText, Header} from '@thryvlabs/maverick'
import {ToolItemContainer} from '../ui/tools-page'

/**
 *
 * @param {{
 *   children: React.ReactNode;
 *   redirectUrl?: string;
 *   onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>void;
 * }} props
 * @returns
 */
const ItemsContainer = ({children, redirectUrl, onClick}) => {
  return (
    <>
      {redirectUrl ? (
        <a href={redirectUrl} target="_blank" rel="noreferrer">
          {children}
        </a>
      ) : (
        <ToolItemContainer onClick={onClick}>{children}</ToolItemContainer>
      )}
    </>
  )
}

/**
 *
 * @param {{
 *   description: string;
 *   imageAlternative: string;
 *   imageAlternativeName: string;
 *   repId: React.MutableRefObject<string>;
 *   val: string;
 *   create: React.MutableRefObject<string>;
 *   tool: React.MutableRefObject<string>;
 *   redirectUrl?: string;
 *   onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>void;
 * }} props
 * @returns
 */
const ToolItem = props => {
  const {
    description,
    imageAlternative,
    imageAlternativeName,
    repId,
    val,
    create,
    tool,
    redirectUrl,
    onClick,
  } = props
  let pacFlag = false,
    seoFlag = false,
    csnFlag = false,
    marketplaceFlag = false

  if (description === 'Package Recommendation') {
    pacFlag = true
  }
  if (description === 'SEO Proposal' || description === 'SEO MBG Proposal') {
    seoFlag = true
  }
  if (description === 'Thryv CSN') {
    csnFlag = true
  }
  if (description === 'Thryv Marketplace Demos') {
    marketplaceFlag = true
  }

  return (
    <div className="mb-4 text-center tool-item">
      <label style={{cursor: 'pointer'}}>
        {pacFlag && (
          <div>
            <input type="hidden" value={val} ref={repId} />
            <input type="hidden" ref={create} value="createTool" />
            <input type="hidden" ref={tool} value="PackageRecommendation" />
          </div>
        )}
        <ItemsContainer redirectUrl={redirectUrl} onClick={onClick}>
          {(seoFlag || marketplaceFlag) && (
            <img
              className="image-fluid display-corrected"
              style={{
                width: '100px',
                height: 'auto',
                maxHeight: '100px',
                aspectRatio: '1/1',
                objectFit: 'contain',
              }}
              src={imageAlternative}
              alt={imageAlternativeName}
            />
          )}
          {csnFlag && (
            <img
              className="image-fluid display-corrected"
              style={{
                width: '100px',
                height: 'auto',
                maxHeight: '100px',
                aspectRatio: '1/1',
                objectFit: 'contain',
              }}
              src={imageAlternative}
              alt={imageAlternativeName}
            />
          )}
          {!seoFlag && !csnFlag && !marketplaceFlag && (
            <img
              className="image-fluid display-corrected"
              style={{
                width: '100px',
                height: 'auto',
                maxHeight: '100px',
                aspectRatio: '1/1',
                objectFit: 'contain',
              }}
              src={imageAlternative}
              alt={imageAlternativeName}
            />
          )}
          {csnFlag && (
            <ParagraphText
              className="mt-2"
              variant="lg"
              color="black"
              fontWeight="normal"
            >
              {description}
            </ParagraphText>
          )}
          {seoFlag && (
            <ParagraphText
              variant="lg"
              color="black"
              fontWeight="normal"
              className="mt-2"
            >
              {description}
            </ParagraphText>
          )}
          {!seoFlag && !csnFlag && (
            <ParagraphText
              className="mt-2"
              variant="lg"
              color="black"
              fontWeight="normal"
            >
              {description}
            </ParagraphText>
          )}
        </ItemsContainer>
        <Header />
      </label>
    </div>
  )
}

export default ToolItem
