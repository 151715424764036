import React, {useContext} from 'react'
// import {Button, Icon} from '@thryvlabs/maverick'
import {useFlags} from 'launchdarkly-react-client-sdk'
import {headerContext} from '../context/header-context'
import {UserDropdown} from './header-components/user-dropdown'
// import {useNavigate, useLocation} from 'react-router'
// import {useWindowDimensions} from '../utils/use-window-dimensions'

import {HeaderContainer} from './ui/header'
import {Link} from 'react-router-dom'
import {thryvSalesLogoNoSubs} from '../assets'

function HeaderKeapPartner() {
  const {isHeaderVisible} = useContext(headerContext)
  const {frontEndUtilityPage} = useFlags()

  //   const navigate = useNavigate()
  //   const {pathname} = useLocation()
  //   const {width: windowWidth} = useWindowDimensions()
  //   const isMobile = windowWidth < 835

  if (!isHeaderVisible) {
    return null
  }

  return (
    <HeaderContainer
      className="header pr-4 pl-4 d-flex border-b border-thryv-gray-light-400 justify-content-between align-items-center"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
      }}
    >
      <UserDropdown isRedirectEnabled={frontEndUtilityPage} hideDropdown />
      <Link to={'/'}>
        <img
          src={thryvSalesLogoNoSubs}
          alt="Thryv Sales Logo"
          style={{height: '40px', marginBottom: '3px'}}
        />
      </Link>
    </HeaderContainer>
  )
}

export {HeaderKeapPartner}
