import React from 'react'
import {Header, ParagraphText, Button, Input} from '@thryvlabs/maverick'
import {NewOrderLayout} from '../components/new-order-layout'
import dayjs from 'dayjs'
import {
  UpcomingAppointmentContainer,
  OrderedList,
  CommitmentSection,
} from '../components/ui/commitment'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {headerContext} from '../context/header-context'

function getFormattedTime(dateStr) {
  return dayjs(dateStr.ISODate).tz(dateStr.timeZoneId).format('h:mm A z')
}
function getFormattedDate(dateStr) {
  return dayjs(dateStr.ISODate).tz(dateStr.timeZoneId).format('MM/DD/YYYY')
}
function UpcomingAppointment({appointment, number, title, description}) {
  return (
    <UpcomingAppointmentContainer>
      <div className="row pt-4">
        <div className="col-2 d-flex">
          <div className="d-block pr-5 vertical-line">
            <div>{getFormattedDate(appointment)}</div>
            <div>{getFormattedTime(appointment)}</div>
          </div>
        </div>
        <div className="appointment-description col-10">
          <span className="appointment-circle mr-5">{number}</span>
          <div className="box-container ml-5" style={{width: '85%'}}>
            <div className="box">
              <span className="arrow-left"></span>
              <div className="font-weight-bold">{title}</div>
              <ParagraphText
                variant="reg"
                color="thryv-gray-medium-500"
                className="mb-3"
              >
                {description}
              </ParagraphText>
            </div>
          </div>
        </div>
      </div>
    </UpcomingAppointmentContainer>
  )
}
function Commitment({
  prevStep,
  clientInitials,
  updateInitials,
  purchaseIntents,
  onboardingCallDate,
  creativeCallDate,
  clientEmail,
  isBillingNoticeDisplayed,
  setIsBillingNoticeDisplayed,
}) {
  const {setIsHeaderVisible} = React.useContext(headerContext)
  const navigate = useNavigate()
  function signAndAccept() {
    if (!clientInitials || clientInitials.length < 2) {
      toast.warn('Please enter the Client Initials')
    } else {
      clientEmail
        ? navigate('/success', {state: {email: clientEmail}})
        : navigate('/success')
    }
  }
  function handleInputChange(e) {
    updateInitials(e.target.value)
  }
  React.useEffect(() => {
    setIsHeaderVisible(true)
  }, [setIsHeaderVisible])
  return (
    <NewOrderLayout
      prevStep={prevStep}
      displayStyle="block"
      centeredHeader={false}
      isBillingNoticeDisplayed={isBillingNoticeDisplayed}
      setIsBillingNoticeDisplayed={setIsBillingNoticeDisplayed}
    >
      <div className="mb-4 ml-5">
        <Header fontWeight="normal" variant="h1" className="mb-3">
          Our Commitment To You
        </Header>
        <CommitmentSection className="mb-5">
          <div className="mb-3">
            <strong>We are dedicated to your success with Thryv</strong>
          </div>

          <ParagraphText
            variant="reg"
            color="thryv-gray-medium-500"
            className="mb-3"
          >
            Over the next several months your Success Manager will work with you
            to ensure your goals are achieved. We will focus on the features you
            felt would help your business the most.
          </ParagraphText>
          <ParagraphText
            variant="reg"
            color="thryv-gray-medium-500"
            className="ml-3"
          >
            {purchaseIntents.firstPurchaseIntent.option}&emsp;&emsp;
            {purchaseIntents.secondPurchaseIntent.option}&emsp;&emsp;
            {purchaseIntents.thirdPurchaseIntent.option}
          </ParagraphText>
        </CommitmentSection>
        {(onboardingCallDate || creativeCallDate) && (
          <CommitmentSection className="pb-5 mb-5">
            <div className="mb-3">
              <strong>Important Upcoming Appointments:</strong>
            </div>
            {onboardingCallDate && (
              <UpcomingAppointment
                appointment={onboardingCallDate}
                number={1}
                title="THRYV KICKOFF CALL"
                description="During the Thryv Kickoff call, our goal is to help get you started using the basics of Thryv."
              />
            )}
            {creativeCallDate && (
              <UpcomingAppointment
                appointment={creativeCallDate}
                number={onboardingCallDate ? 2 : 1}
                title="WEBSITE CONSULTATION"
                description="During this website consultation, we will go over your expectations for your new Thryv website."
              />
            )}
          </CommitmentSection>
        )}
        <CommitmentSection>
          <div className="mb-3">
            <strong>
              To ensure you get the most value from Thryv we will:
            </strong>
          </div>
          <OrderedList style={{columns: '2'}}>
            <li>Appoint a Thryv Success Manager</li>
            <li>Go mobile! Download our app</li>
            <li>Update, fix &amp; lock your listings across the web</li>
            <li>Build out your CRM</li>
            <li>Claim your SMS marketing keyword</li>
            <li>Turn on an automated campaign or two</li>
            <li>Connect your current calendar with Thryv</li>
            <li>
              Choose your competitors &amp; Thryv will keep you posted on their
              reputation
            </li>
          </OrderedList>
        </CommitmentSection>
        <CommitmentSection>
          <ParagraphText
            variant="reg"
            color="thryv-gray-medium-500"
            className="mb-3"
          >
            Teamwork is the key to success. Over the next several months, your
            Thryv Success Manager & your Business Advisor are ready to go & they
            need your participation. In order to get the most out of this
            journey we ask that you:
          </ParagraphText>
          <OrderedList>
            <li>Attend all calls</li>
            <li>Complete exercises provided</li>
            <li>Incorporate Thryv into your business</li>
          </OrderedList>
        </CommitmentSection>
      </div>
      <div className="button__container mt-5 mr-5">
        <Input
          className="w-[100px] mr-2"
          type="text"
          placeholder="Client Initials"
          name="clientInitials"
          withLabel
          labelType="static"
          variant="input-isolated"
          value={clientInitials}
          onChange={handleInputChange}
        />
        <Button type="button" variant="primary" onClick={signAndAccept}>
          Sign & Accept
        </Button>
      </div>
    </NewOrderLayout>
  )
}

export {Commitment}
