import 'core-js/stable'
import * as React from 'react'
import {useAuth0} from '@auth0/auth0-react'
import {useFlags, useLDClient} from 'launchdarkly-react-client-sdk'
import * as Sentry from '@sentry/react'
import AuthenticatedApp from './authenticated-app'
import UnauthenticatedApp from './unauthenticated-app'
import {Header} from './components/header'
import {Footer} from './components/footer'
import {
  AppContainer,
  OldAppContainer,
  AppKeapPartnerContainer,
} from './components/ui/app'
import {AppSpinner} from './components/loader-spinner'
import {HeaderProvider} from './context/header-context'
import {useLocation} from 'react-router'
import {
  InitDataDogRum,
  DataDogStartSession,
  // DataDogEndSession,
} from './dataDogConfig'
import {useAsync} from './utils/use-async'
import {useAuthClient} from './utils/use-auth-client'
import {ToastContainer, toast} from 'react-toastify'
import {SideNavBar} from './components/side-navbar'
import {InviteModalProvider} from './context/invite-modal-context'
import AuthenticatedKeapPartnerApp from './authenticated-kp-app'
import {HeaderKeapPartner} from './components/header-keap-partner'

function Container({
  children,
  frontendSideNav,
  isSidebarOpen,
  isMobileSidebarOpen,
}) {
  if (frontendSideNav) {
    return (
      <AppContainer
        id="app-container"
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
      >
        {children}
      </AppContainer>
    )
  }
  return <OldAppContainer>{children}</OldAppContainer>
}

function ContainerKeapPartner({children}) {
  return (
    <AppKeapPartnerContainer id="app-kp-container">
      {children}
    </AppKeapPartnerContainer>
  )
}

function App() {
  const {isLoading, isAuthenticated, error, user} = useAuth0()
  const LDClient = useLDClient()
  const {pathname} = useLocation()
  const isPaymentInfo = pathname.includes('payment-information')
  const isScanResults = pathname.includes('scan-results')
  const randomId = pathname.split('/')[2]
  const [versionHash, setVersionHash] = React.useState(null)
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(true)
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = React.useState(false)
  const {run} = useAsync()
  const client = useAuthClient()
  const {frontendSideNav} = useFlags()

  console.log('isLoading - App.js', isLoading)
  console.log('isAuthenticated - App.js', isAuthenticated)
  console.log('user - App.js', user)

  React.useEffect(() => {
    if (isAuthenticated) {
      LDClient.identify({
        kind: 'user',
        key: user.email,
        email: user.email,
        name: user.name,
        roles: user.user_roles,
      })
      Sentry.setUser(user)
    }
  }, [LDClient, isAuthenticated, user])

  React.useEffect(() => {
    if (isAuthenticated || (isPaymentInfo && randomId) || isScanResults) {
      if (process.env.NODE_ENV !== 'development') {
        // if (user) {
        //   InitDataDogRum({name: user.name, email: user.email})
        // }
        if (user) {
          InitDataDogRum({name: user.name, email: user.email})
        } else if (isPaymentInfo && randomId) {
          InitDataDogRum({name: randomId, email: ''})
        }
      }
    }
  }, [isAuthenticated, isPaymentInfo, isScanResults, randomId, user])

  React.useEffect(() => {
    // DataDogEndSession()
    if (
      (isAuthenticated || (isPaymentInfo && randomId) || isScanResults) &&
      !pathname.includes('tools')
    ) {
      // if (user) {
      //   DataDogStartSession()
      // }
      if (user || (isPaymentInfo && randomId)) {
        DataDogStartSession()
      }
    }
  }, [isAuthenticated, isPaymentInfo, isScanResults, randomId, user, pathname])

  React.useEffect(() => {
    if (isAuthenticated && window.pendo) {
      window.pendo.initialize({
        visitor: {
          id: user.email,
          full_name: user.name,
          role: user.user_roles,
        },
        account: {
          id: user.email,
          name: user.name,
        },
      })
    }
  }, [isAuthenticated, user])

  React.useEffect(() => {
    console.log('is it triggering process env v1')
    if (!isAuthenticated) return

    let timeout

    const getAppVersion = async () => {
      const response = await run(client('user/hash'))
      console.log(versionHash, response)
      if (versionHash && versionHash !== response) {
        toast.warn(
          'App has been updated, please refresh to get the latest version',
          {autoClose: false},
        )
        return
      }
      if (!versionHash) {
        setVersionHash(response)
        console.log(response)
      }
      timeout = setTimeout(getAppVersion, 300000)
    }
    getAppVersion()

    return () => {
      console.log('moved out of the previous data')
      clearTimeout(timeout)
    }
  }, [isAuthenticated, client, run, versionHash])

  if (isLoading) {
    return <AppSpinner />
  }
  if (error) {
    return <div>Oops... {error.message}</div>
  }

  if (isAuthenticated && !user?.user_roles.includes('KeapPartner')) {
    return (
      <Container
        frontendSideNav={frontendSideNav}
        isSidebarOpen={isSidebarOpen}
        isMobileSidebarOpen={isMobileSidebarOpen}
      >
        <HeaderProvider>
          <InviteModalProvider>
            <Header
              isMobileSidebarOpen={isMobileSidebarOpen}
              setIsMobileSidebarOpen={setIsMobileSidebarOpen}
              isSidebarOpen={isSidebarOpen}
            />
            {frontendSideNav && (
              <SideNavBar
                isSidebarOpen={isSidebarOpen}
                setIsSidebarOpen={setIsSidebarOpen}
                isMobileSidebarOpen={isMobileSidebarOpen}
                setIsMobileSidebarOpen={setIsMobileSidebarOpen}
              />
            )}
            <main>
              <AuthenticatedApp />
            </main>
            <Footer />
          </InviteModalProvider>
        </HeaderProvider>
        <ToastContainer />
      </Container>
    )
  }

  if (isAuthenticated && user?.user_roles.includes('KeapPartner')) {
    return (
      <ContainerKeapPartner>
        <HeaderProvider>
          <HeaderKeapPartner />
          <main>
            <AuthenticatedKeapPartnerApp />
          </main>
        </HeaderProvider>
        <ToastContainer />
      </ContainerKeapPartner>
    )
  }

  return <UnauthenticatedApp />
}

export {App}
