import React from 'react'
import {Button} from '@thryvlabs/maverick'

/**
 *
 * @param {{
 *   url: string;
 *   title: string;
 *   open: boolean;
 *   onClose: ()=>void;
 * }} props
 */
export function ToolItemModal(props) {
  if (!props.open) return null
  const URL = `${process.env.REACT_APP_OOE_TOOLS_URL}/${props.url}`
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: '10',
      }}
    >
      <Button
        variant="primary"
        onClick={props.onClose}
        className="mt-3 mb-3 mr-3 float-right"
      >
        Close
      </Button>
      <iframe
        title={props.title}
        className="mt-3"
        id={props.url}
        src={URL}
        width="95%"
        height="85%"
        style={{
          display: 'block',
          margin: '0 auto',
        }}
      />
    </div>
  )
}
