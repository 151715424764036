import React, {useEffect} from 'react'
import {
  Button,
  ParagraphText,
  Modal,
  Icon,
  RadioGroup,
  Select,
} from '@thryvlabs/maverick'
import dayjs from 'dayjs'
import Calendar from 'react-calendar'
import {toast} from 'react-toastify'
import {useAuthClient} from '../utils/use-auth-client'
import {useAsync} from '../utils/use-async'
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton'
import {ModalContent, SelectDateTimeContainer} from './ui/new-demo'
import {UpdateBusinessInfoModal} from './update-business-info-modal'

const REGION_OPTIONS = [
  {name: 'Coastal', value: 'Coastal'},
  {name: 'Midwest', value: 'Midwest'},
  {name: 'South', value: 'South'},
  {name: 'Inside', value: 'Inside'},
  {name: 'Inbound', value: 'Inbound'},
  {name: 'Partner Channel', value: 'Partner Channel'},
]

function DateTimeModal({
  modalTitle,
  buttonText,
  modalId,
  saveDateTime,
  maxDate,
  defaultDate,
  availableTimeOptions = {},
  staffId,
  selectedTimeStamp,
  address,
  isAlignmentCall = false,
  alignmentCallTimesAvailable,
  isCreativeCall = false,
  customURL = null,
  disabled,
  isFollowup = false,
  radioLanguageOptions,
  setLanguagePreference,
  country,
  isKickoff = false,
  isV5,
  isThryvLeadsOrSeoIncluded = false,
  isRestrictedAccessHIPPASelected = false,
  isMCSelected,
  noneCustomVideoCreativeItem = true,
  isUsSpanishOptionAvailable = false,
  customServiceId,
  callType,
  is5Order,
  onOpen,
  updateAddress,
  setAddressNeedsToBeUpdated,
  onboardingCallDate,
  isNewSEO,
  keapDemo,
}) {
  const client = useAuthClient()
  const {run} = useAsync({
    status: 'pending',
  })
  const [initialLoad, setInitialLoad] = React.useState(true)
  const [isLoading, setIsLoading] = React.useState(false)
  const [showError, setShowError] = React.useState(false)
  const [hours, setHours] = React.useState([])
  const [selectedDay, setSelectedDay] = React.useState(
    defaultDate || new Date(),
  )
  const [selectedHour, setSelectedHour] = React.useState()
  const [selectedHourTimeStamp, setSelectedHourTimeStamp] = React.useState()
  const [selectedStaffId, setSelectedStaffId] = React.useState()
  const [selectedServiceId, setSelectedServiceId] = React.useState()
  const [selectedDateTime, setSelectedDateTime] = React.useState(null)
  const [selectedStartDateTime, setSelectedStartDateTime] = React.useState(null)
  const [timeZoneName, setTimeZoneName] = React.useState(null)
  const [timeZoneId, setTimeZoneId] = React.useState(null)
  const [selectedRegion, setSelectedRegion] = React.useState({
    name: '',
    value: '',
  })
  const [calendarValue, setCalendarValue] = React.useState(null)
  const [isBusinessInfoUpdated, setIsBusinessInfoUpdated] =
    React.useState(false)
  const [updatedFields, setUpdatedFields] = React.useState({})
  const [openCalendar, setOpenCalendar] = React.useState(
    isRestrictedAccessHIPPASelected ||
      isThryvLeadsOrSeoIncluded ||
      country !== 'US' ||
      !isKickoff ||
      !is5Order,
  )
  const [minDate, setMinDate] = React.useState(() => {
    if (isCreativeCall) return new Date(defaultDate)
    if (isNewSEO) {
      let defaultMinDate = new Date()
      defaultMinDate.setDate(defaultMinDate.getDate() + 7)
      return defaultMinDate
    }
    return new Date()
  })

  useEffect(() => {
    if (selectedRegion.value !== '') {
      setOpenCalendar(true)
      setHours([])
      setInitialLoad(true)
      setCalendarValue(new Date())
      setIsLoading(false)

      if (abortControllerRef.current) {
        abortControllerRef.current.abort('A new request has been initiated.')
      }
    }
  }, [selectedRegion])

  const [selectedLanguagePreference, setSelectedLanguagePreference] =
    React.useState({
      name: 'No',
      value: false,
    })
  const abortControllerRef = React.useRef(null)
  const [continueWithoutAlignmentTime, setContinueWithoutAlignmentTime] =
    React.useState(false)
  const isOnboarding = isCreativeCall || isKickoff
  const displayRegionSelect =
    !isRestrictedAccessHIPPASelected &&
    !isThryvLeadsOrSeoIncluded &&
    isKickoff &&
    country === 'US' &&
    !isMCSelected &&
    selectedLanguagePreference?.value !== true &&
    is5Order
  const getMaxDate = () => {
    if (maxDate) return new Date(maxDate)
    let defaultMaxDate = new Date()
    if (isCreativeCall) {
      defaultMaxDate = new Date(defaultDate)
    }
    let dateAdds = [9, 9, 9, 9, 11, 11, 10]
    //let dateAdds = [16, 16, 16, 16, 18, 18, 17]
    if (isCreativeCall && (country === 'AU' || country === 'NZ')) {
      dateAdds = [16, 16, 16, 16, 18, 18, 17]
    }
    if (isNewSEO && !isCreativeCall) {
      defaultMaxDate.setDate(defaultMaxDate.getDate() + 21)
      return defaultMaxDate
    }

    const dayOfWeek = new Date().getDay()
    defaultMaxDate.setDate(defaultMaxDate.getDate() + dateAdds[dayOfWeek])
    return defaultMaxDate
  }

  const showOrderCallLanguagePrefs =
    isV5 &&
    country === 'US' &&
    ((isKickoff &&
      isUsSpanishOptionAvailable &&
      isOnboarding &&
      !isMCSelected &&
      !isThryvLeadsOrSeoIncluded) ||
      (isCreativeCall &&
        noneCustomVideoCreativeItem &&
        !isRestrictedAccessHIPPASelected))

  const showDemoCallLanguagePrefs =
    country === 'US' && (isAlignmentCall || callType === 'demo')

  const scheduledDemo =
    (isAlignmentCall || callType === 'demo') && selectedDateTime

  const onSave = () => {
    const formattedDay = dayjs(selectedDay).format('MMM-DD-YYYY')
    if (selectedHour && formattedDay) {
      const longDate = `${formattedDay} ${selectedHour}`
      const ISODate = dayjs(selectedStartDateTime).toISOString()
      setSelectedDateTime(longDate)
      setShowError(false)
      saveDateTime({
        longDate,
        selectedStaffId,
        selectedHourTimeStamp,
        ISODate,
        timeZoneId,
        timeZoneName,
        selectedServiceId,
        startDateTime: selectedStartDateTime,
        alignmentCallTimesAvailable: true,
        originalApptId: availableTimeOptions?.originalApptId,
        callType,
        selectedLanguagePreference,
        updatedFields,
      })
    } else if (continueWithoutAlignmentTime) {
      alignmentCallTimesAvailable = false
      saveDateTime({alignmentCallTimesAvailable, callType})
    } else {
      setShowError(true)
      return
    }
  }

  const onSelectHour = ({
    staff_id,
    local_start_time,
    timeStamp,
    service_id,
    startDateTime,
  }) => {
    setSelectedStaffId(staff_id)
    setSelectedHour(local_start_time)
    setSelectedHourTimeStamp(timeStamp)
    setSelectedServiceId(service_id)
    setSelectedStartDateTime(startDateTime)
  }

  const fetchAvailableHours = React.useCallback(
    async selectedCalendarDay => {
      setIsLoading(true)
      const formattedDate = dayjs(selectedCalendarDay || selectedDay).format(
        'YYYY-MM-DD',
      )
      let url = ''
      if (customURL) {
        url = `${customURL}&date=${formattedDate}`
      } else if (isFollowup && Object.keys(updatedFields).length === 0) {
        url =
          `demos/available-times?date=${formattedDate}&isFollowup=${true}` +
          `&staff_id=${availableTimeOptions.staffId}` +
          `&country=${availableTimeOptions.prospectsCountry}` +
          `&address=${encodeURIComponent(
            availableTimeOptions.streetAddress,
          )}%20${availableTimeOptions.prospectsCity}%20${
            availableTimeOptions.prospectsState
          }`
      } else if (isFollowup && updatedFields) {
        url =
          `demos/available-times?date=${formattedDate}&isFollowup=${true}` +
          `&staff_id=${availableTimeOptions.staffId}` +
          `&country=${updatedFields.prospectsCountry.value}` +
          `&address=${encodeURIComponent(updatedFields.streetAddress)}%20${
            updatedFields.prospectsCity
          }%20${updatedFields.prospectsState.value}`
      } else {
        url =
          `demos/available-times?date=${formattedDate}` +
          `&country=${availableTimeOptions.prospectsCountry}` +
          `&isMarketingPlatform=${availableTimeOptions.isMarketingPlatform}` +
          `&isSpanish=${availableTimeOptions.demoConductedInSpanish}` +
          `&address=${address}` +
          `&isFormerVivialCustomer=${availableTimeOptions.isFormerVivialCustomer}` +
          `&isKeapDemo=${keapDemo}`
      }
      if (availableTimeOptions.regionId) {
        url = `${url}&region=${availableTimeOptions.regionId}`
      }
      if (!isAlignmentCall) {
        if (availableTimeOptions.isMarketingCenter) {
          url = `${url}&isMarketingCenter=${availableTimeOptions.isMarketingCenter}`
        }
      } else if (isAlignmentCall) {
        url =
          `${url}&staff_id=${staffId}` +
          `&isAlignment=${isAlignmentCall}` +
          `&isMarketingCenter=${availableTimeOptions.isMarketingCenter}`
      }

      if (customServiceId) {
        url += `&serviceId=${customServiceId}`
      }

      if (selectedRegion.value !== '') {
        url += `&region=${selectedRegion.value}`
      }

      if (abortControllerRef.current) {
        abortControllerRef.current.abort('A new request has been initiated.')
      }

      abortControllerRef.current = new AbortController()
      const abortSignal = abortControllerRef.current.signal

      try {
        const {
          warning,
          availableTimes,
          availableDemoTimes,
          timeZone,
          serviceIdTitle,
          serviceId,
        } = await run(client(url, {signal: abortSignal}))

        if (warning) {
          const {message} = warning[0]
          toast.warning(message)
        }

        let _availableDemoTimes = []
        if (customURL) {
          setTimeZoneName(timeZone.timeZoneName)
          setTimeZoneId(timeZone.timeZoneId)
          _availableDemoTimes = availableTimes.map(hour => {
            const availableTime = Date.parse(hour.startTime)
            return {
              ...hour,
              staff_id: hour.staffId,
              local_start_time: dayjs(hour.startTime)
                .utc()
                .tz(timeZone.timeZoneId)
                .format('hh:mm A'),
              timeStamp: availableTime / 1000,
              service_id: serviceIdTitle.serviceId,
            }
          })
        } else {
          setTimeZoneName(timeZone.timeZoneName)
          setTimeZoneId(timeZone.timeZoneId)
          _availableDemoTimes = availableDemoTimes.map(hour => {
            const availableTime = Date.parse(hour.start_time)
            return {
              ...hour,
              local_start_time: dayjs(hour.start_time)
                .utc()
                .tz(timeZone.timeZoneId)
                .format('hh:mm A'),
              timeStamp: availableTime / 1000,
              service_id: serviceId,
            }
          })
        }

        if (selectedTimeStamp) {
          _availableDemoTimes = _availableDemoTimes.filter(
            ({timeStamp}) => selectedTimeStamp > timeStamp,
          )
        }

        if (_availableDemoTimes.length === 0) {
          setContinueWithoutAlignmentTime(true)
        } else {
          setContinueWithoutAlignmentTime(false)
        }

        setHours(_availableDemoTimes)
        setIsLoading(false)
        abortControllerRef.current = null
      } catch (err) {
        if (abortSignal.aborted === false) {
          toast.error(err.message)
          setIsLoading(false)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDay, availableTimeOptions, staffId, address, updatedFields],
  )

  const getDayDiff = date => {
    const onboardingDate = dayjs(onboardingCallDate.longDate.split(' ')[0])
    const dayDiff = onboardingDate.diff(date, 'day')
    if (!dayDiff) date.setDate(date.getDate() + 1)
  }

  useEffect(() => {
    if (
      country === 'US' &&
      (isMCSelected || selectedLanguagePreference?.value === true) &&
      is5Order
    ) {
      setOpenCalendar(true)
    } else if (
      country === 'US' &&
      displayRegionSelect === true &&
      selectedLanguagePreference?.value === false &&
      selectedRegion?.value === '' &&
      is5Order
    ) {
      setOpenCalendar(false)
    }
  }, [
    country,
    displayRegionSelect,
    is5Order,
    isMCSelected,
    selectedLanguagePreference?.value,
    selectedRegion?.value,
  ])

  useEffect(() => {
    if (isFollowup && updateAddress && !isBusinessInfoUpdated) {
      setOpenCalendar(false)
    }
    if (
      isFollowup &&
      (!updateAddress || (updateAddress && isBusinessInfoUpdated))
    ) {
      setOpenCalendar(true)
    }
  }, [isFollowup, updateAddress, isBusinessInfoUpdated])

  useEffect(() => {
    const formatDefaultValue = () => {
      if (defaultDate) {
        const formattedDate = new Date(dayjs(defaultDate).format('YYYY/MM/DD'))
        if (isCreativeCall && onboardingCallDate) {
          getDayDiff(formattedDate)
        }
        return formattedDate
      }
      return selectedDay
    }
    if (initialLoad === true) {
      setCalendarValue(formatDefaultValue())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    defaultDate,
    initialLoad,
    selectedDay,
    isCreativeCall,
    onboardingCallDate,
  ])

  const onSelectedDay = selectedCalendarDay => {
    if (initialLoad) {
      setInitialLoad(false)
    }
    setSelectedDay(selectedCalendarDay)
    fetchAvailableHours(selectedCalendarDay)
  }

  const getButtonText = () => {
    if (isFollowup) {
      return 'Schedule Follow-up'
    } else {
      return isAlignmentCall && continueWithoutAlignmentTime
        ? 'Continue without Alignment Call'
        : 'Save'
    }
  }

  const onLanguagePreferenceChange = e => {
    setSelectedLanguagePreference(e)
    setLanguagePreference(e)
    setSelectedDay(defaultDate || new Date())
    setHours([])
    setSelectedStaffId()
    setSelectedHour()
    setSelectedHourTimeStamp()
    setSelectedServiceId()
    setSelectedStartDateTime()
  }

  const handleContinue = data => {
    const updatedBusinessInfo = {
      streetAddress: data.streetAddress,
      prospectsCity: data.prospectsCity,
      prospectsState: data.prospectsState,
      prospectsCountry: data.prospectsCountry,
    }
    setUpdatedFields(updatedBusinessInfo)
  }

  React.useEffect(() => {
    if (isCreativeCall) {
      const minimumDate = new Date(defaultDate)
      if (onboardingCallDate) {
        getDayDiff(minimumDate)
      }
      setMinDate(minimumDate)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultDate, isCreativeCall, onboardingCallDate])

  const modalProps = {
    variant: 'lg',
    title: modalTitle,
    footerCancel: true,
    btnActionText: getButtonText(),
    actionClose: true,
    footer: true,
    action: true,
    onOpen: onOpen,
    onClose: () => {
      setTimeout(() => {
        setHours([])
        setInitialLoad(true)
      }, 300)
      if (isFollowup) {
        setAddressNeedsToBeUpdated(false)
      }
    },
    id: modalId,
    disableModal: disabled,
    btnAction: onSave,
  }
  if (callType === 'demo' || callType === 'alignment') {
    modalProps.altBtn = (
      <Button
        variant={
          scheduledDemo || buttonText === 'No Alignment Times Available'
            ? 'text'
            : 'primary'
        }
        buttonType={
          scheduledDemo || buttonText === 'No Alignment Times Available'
            ? ''
            : 'outlined'
        }
        level={
          scheduledDemo || buttonText === 'No Alignment Times Available'
            ? 1
            : ''
        }
      >
        {buttonText}
      </Button>
    )
  }
  if (callType !== 'demo' && !isAlignmentCall) {
    modalProps.btnText = buttonText
    modalProps.btnType = isFollowup ? 'secondary' : 'primary'
  }
  if (isAlignmentCall) {
    modalProps.btnText = buttonText
    modalProps.btnType = 'primary'
  }
  if (updateAddress) {
    modalProps.btnActionDisabled = !isBusinessInfoUpdated || !selectedHour
  }
  if (isFollowup && !updateAddress) {
    modalProps.btnActionDisabled = !selectedHour
  }

  return (
    <div className="d-flex align-items-center dateTimeContainer">
      <Modal {...modalProps}>
        <ModalContent>
          <div style={{gridRowStart: 1, justifySelf: 'start'}} className="pb-2">
            {(showOrderCallLanguagePrefs || showDemoCallLanguagePrefs) && (
              <RadioGroup
                data={radioLanguageOptions}
                value={selectedLanguagePreference}
                label="Would you like to schedule this call in Spanish?"
                row
                layoutStyles={{gap: '10px'}}
                onChange={onLanguagePreferenceChange}
                disabled={isLoading}
              />
            )}
            {displayRegionSelect && (
              <div className="mb-5" style={{marginTop: '30px'}}>
                <Select
                  selectLabel="Select Region"
                  options={REGION_OPTIONS}
                  width="md"
                  selectedOption={selectedRegion}
                  setSelectedOption={setSelectedRegion}
                />
              </div>
            )}
          </div>
          {updateAddress && !isBusinessInfoUpdated && (
            <>
              <ParagraphText variant="lg">
                No business address information found. Please, update the
                address to continue.
              </ParagraphText>
              <UpdateBusinessInfoModal
                nextStep={handleContinue}
                setIsBusinessInfoUpdated={setIsBusinessInfoUpdated}
                isBusinessInfoUpdated={isBusinessInfoUpdated}
              />
            </>
          )}
          {openCalendar && (
            <SelectDateTimeContainer style={{gridRowStart: 2}}>
              {showError && (
                <ParagraphText variant="lg">
                  Please, select a date
                </ParagraphText>
              )}
              <div className="mt-4">
                <ParagraphText variant="lg">Select a date</ParagraphText>
                <div className="calendar" style={{height: '275px'}}>
                  <Calendar
                    minDate={minDate}
                    maxDate={getMaxDate()}
                    value={calendarValue}
                    onChange={setCalendarValue}
                    calendarType="US"
                    next2Label={null}
                    prev2Label={null}
                    nextLabel={
                      <Icon
                        type="solid"
                        variant="arrowRight"
                        className="m-auto"
                      />
                    }
                    prevLabel={
                      <Icon
                        type="solid"
                        variant="arrowLeft"
                        className="m-auto"
                      />
                    }
                    tileClassName="calendar-tile"
                    onClickDay={onSelectedDay}
                  />
                </div>
              </div>
              <div className="ml-4">
                <ParagraphText variant="lg" className="mb-0 mt-4">
                  Select a time
                </ParagraphText>
                {timeZoneName && (
                  <ParagraphText variant="lg">
                    <b>({timeZoneName})</b>
                  </ParagraphText>
                )}
                <div className={`hours-container ${isLoading && 'isLoading'}`}>
                  {isLoading ? (
                    <>
                      <SkeletonTheme color="#e6e6e6" highlightColor="#fff">
                        <Skeleton
                          containerTestId="new-demo-request-hours-loader"
                          height="35px"
                          width="100%"
                        />
                        <Skeleton className="mt-2" height="35px" width="100%" />
                        <Skeleton className="mt-2" height="35px" width="100%" />
                        <Skeleton className="mt-2" height="35px" width="100%" />
                        <Skeleton className="mt-2" height="35px" width="100%" />
                        <Skeleton className="mt-2" height="35px" width="100%" />
                        <Skeleton className="mt-2" height="35px" width="100%" />
                      </SkeletonTheme>
                    </>
                  ) : (
                    <>
                      {hours.length === 0 ? (
                        initialLoad ? (
                          <>
                            <p className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                              Please select a date to view available
                              appointments.
                            </p>
                          </>
                        ) : (
                          <>
                            {isAlignmentCall ? (
                              <p className="text-btn-links font-bold font-montserrat text-thryv-black-500">
                                There are no times available for an alignment
                                call on this date. If an alignment call cannot
                                be scheduled, please reach out to your SST to
                                speak about the account prior to the scheduled
                                demo.
                              </p>
                            ) : (
                              <p className="text-btn-links font-normal font-montserrat text-thryv-black-500">
                                Sorry, we could not find any results for the
                                given date. Try selecting a different date.
                              </p>
                            )}
                          </>
                        )
                      ) : (
                        <>
                          {hours.map(
                            (
                              {
                                local_start_time,
                                staff_id,
                                timeStamp,
                                service_id,
                                startDateTime,
                              },
                              i,
                            ) => (
                              <Button
                                key={i}
                                variant="secondary"
                                className={`hour-option ${
                                  selectedHour === local_start_time
                                    ? 'selected'
                                    : ''
                                }`}
                                onClick={() =>
                                  onSelectHour({
                                    staff_id,
                                    local_start_time,
                                    timeStamp,
                                    service_id,
                                    startDateTime,
                                  })
                                }
                              >
                                {local_start_time}
                              </Button>
                            ),
                          )}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </SelectDateTimeContainer>
          )}
        </ModalContent>
      </Modal>
      {selectedDateTime && (
        <ParagraphText
          variant="reg"
          className="ml-2 mb-0"
          data-testid="select-date-time"
        >
          {selectedDateTime}
        </ParagraphText>
      )}
    </div>
  )
}

export {DateTimeModal}
