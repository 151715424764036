import React from 'react'
import {useGenerateBuyLinkContext} from './generate-buy-now-link-provider'
import {KeapPartnerPrimaryButton} from './styled.components'

export function GenerateBuyNowLinkButton() {
  const {openGenerateLinkModal} = useGenerateBuyLinkContext()
  return (
    <KeapPartnerPrimaryButton onClick={openGenerateLinkModal}>
      Generate Buy Now Link
    </KeapPartnerPrimaryButton>
  )
}
